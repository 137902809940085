export const ConfigureTruck = (values) => {
  return {
    initialValue: values?.initialValue ?? 0,
    purchaseDate: values.purchaseDate ? values.purchaseDate.toDate() : null,
    depreciationYears: values?.depreciationYears ?? null,
    serviceDate: values.serviceDate ? values.serviceDate.toDate() : null,
    description: values?.description ?? "",
    name: values?.name ?? "",
    truckNotes: values?.truckNotes ?? "",
    isAvailable: values?.isAvailable ?? true,
    createdOn: new Date(),
  };
};
