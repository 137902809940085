import React, { createContext } from "react";
import { Affix, Col, Divider, PageHeader, Row, Spin } from "antd";
import SalesStatistic from "../sales/components/SalesStatistic";
import { useDepreciationReport } from "./useDepreciationReport";
import InventoryDepreciationTable from "./components/InventoryDepreciationTable";
import DepreciationPie from "./components/DepreciationPie";
import DepreciationTabView from "./components/DepreciationTabView";

export const DepreciationReportContext = createContext();
const DepreciationReport = () => {
  const { state, actions } = useDepreciationReport();
  return (
    <div>
      <DepreciationReportContext.Provider value={{ state, actions }}>
        <Spin spinning={state.loading}>
          <Affix>
            <PageHeader className="PageHeader" title="Depreciation Report" />
          </Affix>
          <div style={{ margin: "16px", paddingTop: "46px" }}>
            <DepreciationTabView />
          </div>
        </Spin>
      </DepreciationReportContext.Provider>
    </div>
  );
};

export default DepreciationReport;
