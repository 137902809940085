import { USERS_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import notificationError from "../../../system-components/toasters/notificationError";

export function userWrites({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${USERS_COLLECTION}`);

  async function updateUserDetails(id, payload) {
    return await ref.doc(id).update(payload);
  }
  async function deleteUserDoc(id) {
    return await ref.doc(id).delete();
  }
  return {
    updateUserDetails: updateUserDetails, // update user details
    deleteUserDoc: deleteUserDoc, // delete user document -> cf trigger handles deleting auth
  };
}
