export const configureNewUser = (formValues, creatorId, orgId) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    permissions,
    tempPassword,
  } = formValues;
  return {
    orgId: orgId ? orgId : "",
    firstName: firstName ? firstName.trim() : "",
    lastName: lastName ? lastName.trim() : "",
    email: email ? email.trim() : "",
    phone: phone ? phone.trim() : "",
    permissions: permissions ? permissions : "admin",
    createdOn: new Date(),
    updatedOn: new Date(),
    createdBy: creatorId,
    updatedBy: creatorId,
    firstTimeLogin: true,
    tempPassword: tempPassword ? tempPassword : "",
  };
};
