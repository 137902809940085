import React, { useContext } from "react";
import { Select } from "antd";
import { UsageReportContext } from "../UsageReport";

const FilterByCategory = () => {
  const { state, actions } = useContext(UsageReportContext);
  return (
    <Select
      placeholder={"Filter by Category"}
      mode={"multiple"}
      showSearch
      // onSearch={(e) => setSearch(e)}
      style={{ width: "250px" }}
      onChange={(items) => actions.changeItemFilters(items)}
    >
      {state.categories?.map((i) => {
        return (
          <Select.Option key={i?.id} value={i?.id}>
            {i?.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FilterByCategory;
