import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  List,
  Row,
  Space,
  DatePicker,
  TimeRangePickerProps,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import { Moment } from "moment";

export const EmbeddedBlackoutDates = () => {
  const { actions, state } = useContext(AdminEmbeddedContext);
  const [dates, setDates] = useState<[Moment, Moment] | null>(null);
  const [willCallAllowed, setWillCallAllowed] = useState(false);
  const resetDatePicker = () => {
    setDates(null);
    setWillCallAllowed(false);
  };

  const onChange: TimeRangePickerProps["onChange"] = (values) => {
    if (
      values === null ||
      (Array.isArray(values) &&
        values.length === 2 &&
        values[0] !== null &&
        values[1] !== null)
    ) {
      setDates(values as [Moment, Moment]);
    } else setDates(null);
  };
  return (
    <Card size={"small"} title={"Blackout Dates"} style={{ marginTop: "24px" }}>
      <Row>
        <Col span={24}>
          <Space size={8} align={"start"}>
            <Space direction={"vertical"} size={4}>
              <DatePicker.RangePicker
                value={dates}
                onChange={onChange}
                // onChange={(dates: [Moment, Moment]) => {
                //   setDates(dates);
                // }}
                format={"MMM Do YYYY"}
              />
              <Space direction={"horizontal"}>
                <Checkbox
                  checked={willCallAllowed}
                  onChange={(v) => setWillCallAllowed(v.target.checked)}
                />
                Will-Call Allowed
              </Space>
            </Space>
            <Button
              onClick={(v) => {
                if (!dates) {
                  console.error(
                    "cannot add blackout dates without dates selected"
                  );
                  return;
                }
                actions.blackoutDates.addBlackoutDate({
                  blackoutStart: dates[0],
                  blackoutEnd: dates[1],
                  willCallAllowed: willCallAllowed,
                });
                resetDatePicker();
              }}
            >
              Add Blackout Date
            </Button>
          </Space>
        </Col>
      </Row>
      <List
        header={"Dates"}
        style={{ maxHeight: "500px", overflowY: "auto", marginTop: "12px" }}
        itemLayout="horizontal"
        dataSource={state.blackoutDates}
        locale={{ emptyText: "No dates" }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type={"link"}
                onClick={() => {
                  actions.blackoutDates.removeBlackoutDate(item);
                }}
              >
                <CloseCircleFilled style={{ color: COLOR_RED_0 }} />
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <TextBody
                  text={`${item.blackoutStart.format(
                    "MMM Do YYYY"
                  )} - ${item.blackoutEnd.format("MMM Do YYYY")}`}
                />
              }
              description={item.willCallAllowed ? `Will-Call Allowed*` : ""}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
