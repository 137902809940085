import { toTitleCase } from "../../app/utils/casing/titleCase";
import { ClickableHoverStyle } from "../../app/ui/ClickableHoverStyle";

export const adminUsersColumns = (handleUserClicked) => [
  {
    title: "First Name",
    dataIndex: "firstName",
    width: 200,
    render: (text, record) => (
      <span
        onClick={() => handleUserClicked(record)}
        style={ClickableHoverStyle}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    width: 200,
    render: (text, record) => (
      <span
        onClick={() => handleUserClicked(record)}
        style={ClickableHoverStyle}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    width: 200,
  },
  {
    title: "Permissions",
    dataIndex: "permissions",
    width: 150,
    render: (text) => toTitleCase(text),
  },
];
