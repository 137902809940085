import React, { useState } from "react";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import UserContextMenu from "./UserContextMenu";
import AdminUsersTable from "./AdminUsersTable";
import EditUserModal from "./EditUserModal";
import notificationError from "../../../app/system-components/toasters/notificationError";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import { userWrites } from "../../../app/services/firestore/writes/userWrites";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { adminUsersColumns } from "../AdminUsersColumns";
import { resetPasswordEmail } from "../../auth/resetPasswordEmail";

const { confirm } = Modal;
const UsersContextMenuWrapper = ({
  history,
  data,
  onEditComplete,
  loading,
  onDeleteComplete,
}) => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const firebase = useFirebase();
  const [contextMenu, setContextMenu] = useState({
    user: null,
    visible: false,
    x: 0,
    y: 0,
  });
  const [userModal, setUserModal] = useState({
    visible: false,
    user: null,
  });

  const firestore = useFirestore();

  const handleEdit = () => {
    setUserModal({
      visible: true,
      user: contextMenu.user,
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      title: "Delete user?",
      content: "This cannot be undone",
      okType: "danger",
      onOk() {
        return new Promise(async (resolve, reject) => {
          if (!fsOrgPrefix) {
            return notificationError(
              "Something went wrong",
              "Please try again later"
            );
          } else {
            // delete user
            await userWrites({ firestore }, fsOrgPrefix).deleteUserDoc(
              contextMenu.user.id
            );
            resolve();
            onDeleteComplete();
            notificationConfirm("User deleted", "");
          }
        }).catch((err) => {
          console.log(err);
          notificationError("Something went wrong", "Please try again later");
        });
      },
      onCancel() {},
    });
  };

  const handleUsersClicked = (userData) => {
    setUserModal({
      visible: true,
      user: userData,
    });
  };

  const configureMenuOpts = (context) => {
    setContextMenu({
      user: context.user,
      visible: context.visible,
      x: context.x,
      y: context.y,
    });
  };

  const handleClose = () => {
    setUserModal({
      visible: false,
      user: null,
    });
  };

  const editFinished = () => {
    handleClose();
    onEditComplete();
  };

  const handleResetPassword = (user) => {
    confirm({
      title: "Reset Users Password?",
      icon: <></>,
      content: `This will send an email to ${user.email} with instructions to complete the reset password process.`,
      okText: "Reset Password",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          resetPasswordEmail(
            { firebase },
            user.email,
            "Password reset email sent"
          ).then(() => {
            resolve();
          });
        }).catch((err) => {
          notificationError("Something went wrong", "Please contact support");
          console.log("Password Reset Email error", err);
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div>
      {userData && orgData && (
        <AdminUsersTable
          columns={adminUsersColumns(handleUsersClicked)}
          data={data && data}
          setContextMenu={configureMenuOpts}
          contextMenu={contextMenu}
          loading={loading}
        />
      )}
      <UserContextMenu
        user={contextMenu.user}
        visible={contextMenu.visible}
        x={contextMenu.x}
        y={contextMenu.y}
        history={history}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleResetPassword={handleResetPassword}
      />
      <EditUserModal
        visible={userModal.visible}
        user={userModal.user}
        onCancel={handleClose}
        onEditComplete={editFinished}
      />
    </div>
  );
};

export default UsersContextMenuWrapper;
