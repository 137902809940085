const OAuthClient = require("intuit-oauth");

export const qboConfig = {
  clientId: process.env.REACT_APP_QBO_CLIENT_ID,
  clientSecret: process.env.REACT_APP_QBO_CLIENT_SECRET,
  environment: process.env.REACT_APP_QBO_ENV,
  redirectUri: process.env.REACT_APP_QBO_REDIRECT_URL,
};
export const qboClient = new OAuthClient({
  ...qboConfig,
});
export const authUri = qboClient.authorizeUri({
  scope: [OAuthClient.scopes.Accounting, OAuthClient.scopes.OpenId], // scopes to req access
  state: process.env.REACT_APP_QBO_STATE,
});
