import React, { useContext } from "react";
import { Select } from "antd";
import { TaxReportContext } from "../TaxReport";

const SelectTaxItems = () => {
  const { state, actions } = useContext(TaxReportContext);
  return (
    <Select
      placeholder={"Filter by Tax Rate"}
      mode={"multiple"}
      showSearch
      style={{ width: "300px" }}
      onChange={(items) => actions.changeItemFilters(items)}
    >
      {state.taxData?.map((i) => {
        return (
          <Select.Option key={i?.taxId} value={i?.taxId}>
            {i?.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default SelectTaxItems;
