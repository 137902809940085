import React from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import TextBody from "../../../app/system-components/typography/text/TextBody";

const UserContextMenu = ({
  user,
  visible,
  x,
  y,
  handleEdit,
  handleDelete,
  handleResetPassword,
  history,
}) => {
  const { userData } = useSelector(authSelector);
  return (
    <div>
      {visible && userData && user && (
        <ul
          className="tableContextMenu"
          style={{ left: `${x}px`, top: `${y}px`, padding: "4px 0" }}
        >
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleEdit}
          >
            <TextBody text={"Edit User"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={() => handleResetPassword(user)}
          >
            <TextBody text={"Reset Password"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleDelete}
          >
            <TextBody text={"Delete"} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserContextMenu;
