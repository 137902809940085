import { ROW_TYPE_SUBTOTAL } from "../../../../utils/models/modelConstants/modelConstants";

export const GetSubtotalsForInvoices = (invoices) => {
  if (!invoices) return 0;
  const totals = invoices?.reduce((prev, next) => {
    const invoiceItemTotal = next?.selectedItems?.items?.reduce(
      (prevItem, nextItem) => {
        const isSubtotal = nextItem?.rowType === ROW_TYPE_SUBTOTAL;
        if (isSubtotal) return prevItem + 0; // escape subtotals
        const amt = nextItem?.rowTotal ?? 0;
        const safeAmt = isNaN(amt) ? 0 : amt;
        return prevItem + safeAmt;
      },
      0
    );
    const invTotal = invoiceItemTotal ?? 0;
    return prev + invTotal;
  }, 0);
  return totals ?? 0;
};
