import { Affix, Button, PageHeader, Spin } from "antd";
import { Spinner } from "../../app/ui/spinner";
import { useEmbeddedSettings } from "./useEmbeddedSettings";
import { AdminEmbeddedContext } from "./adminEmbeddedContext";
import { EmbeddedSettings } from "./components/EmbeddedSettings";
import { EmbeddedCodeSnippet } from "./components/EmbeddedCodeSnippet";
import { AddAllInventoryToShop } from "./components/AddAllInventoryToShop";

const AdminEmbeddedCheckout = () => {
  const EmbeddedSettingsMgr = useEmbeddedSettings();
  return (
    <div>
      <AdminEmbeddedContext.Provider value={EmbeddedSettingsMgr}>
        <Spin spinning={EmbeddedSettingsMgr.busy} indicator={Spinner}>
          <Affix>
            <PageHeader
              className="PageHeader"
              backIcon={false}
              title="Embedded Checkout"
              extra={
                <>
                  <AddAllInventoryToShop />
                  <Button
                    type={"primary"}
                    onClick={async () => await EmbeddedSettingsMgr.save()}
                  >
                    Save
                  </Button>
                </>
              }
            />
          </Affix>
          <div style={{ paddingTop: "36px", margin: "20px" }}>
            <EmbeddedCodeSnippet />
            <EmbeddedSettings />
          </div>
        </Spin>
      </AdminEmbeddedContext.Provider>
    </div>
  );
};

export default AdminEmbeddedCheckout;
