import React, { useContext, useEffect, useState } from "react";
import { DepreciationReportContext } from "../DepreciationReport";
import { Table } from "antd";
import { invDepreciationColumns } from "./InvDepreciationColumns";
import { TrucksDepreciationColumns } from "./trucksDepreciationColumns";

const TruckDepreciationTable = () => {
  const { state, actions } = useContext(DepreciationReportContext);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (state.searchStr === "") return setFilteredData(state.truckReportData);
    const filtered = state.truckReportData?.filter((i) => {
      const str = state.searchStr.toLowerCase();
      const name = i?.name.toLowerCase();
      return !!name.includes(str);
    });
    setFilteredData(filtered);
  }, [state.searchStr, state.truckReportData]);
  return (
    <Table
      className="ManageInventoryTable"
      style={{ margin: "12px" }}
      size="small"
      columns={TrucksDepreciationColumns}
      dataSource={filteredData}
      rowKey={"id"}
      pagination={{ defaultPageSize: 50 }}
      locale={{ emptyText: "No trucks to show" }}
      scroll={{ y: "calc(100vh - 176px)" }}
    />
  );
};

export default TruckDepreciationTable;
