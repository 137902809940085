import TextBody from "../../app/system-components/typography/text/TextBody";
import convertTimeToDayString from "../../app/utils/time/convertTimeToDayString";
import { useContext } from "react";
import { TrucksContext } from "./AdminTrucks";
export const truckColumns = ({ onTitleClick }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return (
          <div onClick={() => onTitleClick(record)}>
            {" "}
            <TextBody style={{ cursor: "pointer" }} text={text} />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Service Date",
      dataIndex: "serviceDate",
      render: (date, record) => {
        const str = date && convertTimeToDayString(date);
        return <TextBody text={str} />;
      },
    },
  ];
};
