import { Button, Card, Checkbox, Col, List, Row, Space } from "antd";
import { useContext } from "react";
import { COLOR_LIGHT_BLUE, COLOR_RED_0 } from "../../../app/ui/colorConstants";
import QuestionInput from "./QuestionInput";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

export const EmbeddedCheckoutQuestions = () => {
  const { actions, state } = useContext(AdminEmbeddedContext);
  return (
    <Card
      size={"small"}
      style={{ marginTop: "24px" }}
      title={"Checkout Questions"}
    >
      <List
        locale={{ emptyText: "No questions" }}
        header={
          <Button
            onClick={() => {
              actions.checkoutQuestions.create();
            }}
          >
            + Add Question
          </Button>
        }
        style={{ maxHeight: "500px", overflowY: "auto", marginTop: "12px" }}
        itemLayout="horizontal"
        dataSource={state.checkoutQuestions}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button
                type={"link"}
                onClick={() => actions.checkoutQuestions.remove(item.index)}
              >
                <CloseCircleFilled style={{ color: COLOR_RED_0 }} />
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Card
                  size={"small"}
                  bordered={false}
                  style={{ background: COLOR_LIGHT_BLUE, borderRadius: "8px" }}
                >
                  <Row justify={"start"} align={"middle"} gutter={8}>
                    <Col flex={"33px"}>
                      <Space size={4} direction={"horizontal"} align={"center"}>
                        {item.index + 1}
                        <Space size={0} direction={"vertical"} align={"center"}>
                          <Button
                            size={"small"}
                            type={"link"}
                            onClick={() =>
                              actions.checkoutQuestions.moveUp(item)
                            }
                          >
                            <CaretUpOutlined />
                          </Button>
                          <Button
                            size={"small"}
                            type={"link"}
                            onClick={() =>
                              actions.checkoutQuestions.moveDown(item)
                            }
                          >
                            <CaretDownOutlined />
                          </Button>
                        </Space>
                      </Space>
                    </Col>
                    <Col span={20}>
                      <QuestionInput item={item} />
                    </Col>
                    <Col flex={"auto"}>
                      <Space>
                        <Checkbox
                          checked={item.isRequired}
                          onChange={(v) =>
                            actions.checkoutQuestions.toggleRequired({
                              index: item.index,
                              question: item.question,
                              isRequired: v.target.checked,
                            })
                          }
                        />
                        Required
                      </Space>
                    </Col>
                  </Row>
                </Card>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};