/**
 * Retrieve stripe account details
 * payload should be org id
 * @param firebase
 * @param payload
 * @returns {Promise<HttpsCallableResult>}
 */
export async function retrieveStripeAccount({ firebase }, payload) {
  const call = firebase.functions().httpsCallable("retrieveConnectAccount");
  return await call(payload);
}
