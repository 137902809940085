import React, { useContext } from "react";
import { Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import { UsageReportContext } from "../UsageReport";
import { capitalizeFirstLetter } from "../../../../app/utils/casing/capitalizeFirstLetter";

const { Text } = Typography;
const UsageItemTooltip = ({ payload }) => {
  const { state, actions } = useContext(UsageReportContext);
  const configureAmt = () => {
    if (!payload) return 0;
    return {
      usage: payload?.[0]?.payload?.usage ?? 0,
      overflow: payload?.[0]?.payload?.overflow ?? 0,
      maxPotential: payload?.[0]?.payload?.maxPotential ?? 0,
    };
  };
  const amt = configureAmt();
  return (
    <Card size={"small"}>
      <Text>
        Lost Potential:{" "}
        {amt.maxPotential.toLocaleString("us", { maximumFractionDigits: 2 })}
      </Text>
      <br />
      <Text>
        Overused:{" "}
        {amt.overflow.toLocaleString("us", { maximumFractionDigits: 2 })}
      </Text>
      <br />
      <Text>
        Usage: {amt.usage.toLocaleString("us", { maximumFractionDigits: 2 })}
      </Text>
      <br />
      <Text type={"secondary"}>
        {`(In ${capitalizeFirstLetter(state.viewIntervalRange)}s)`}
      </Text>
    </Card>
  );
};

export default UsageItemTooltip;
