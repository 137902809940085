import { EnumerateBetweenDates } from "./enumerateBetweenDates";
import { ReduceStockByDay } from "../reduceStockByDay";
import moment from "moment";
import { DayWithinRange } from "../dayWithinRange";

export const GetOverbookedInventoryForRange = ({
  startDate,
  endDate,
  inventory,
  invoicesInRange,
  useTurnaround,
}) => {
  const dates = EnumerateBetweenDates(startDate, endDate);

  return dates?.map((date) => {
    let invList = inventory?.map((i) => {
      return {
        inventoryId: i?.id,
        name: i?.name ?? "",
        overbookedAmt: 0,
      };
    });
    const dayInvoices = invoicesInRange.filter((i) => {
      return DayWithinRange({
        tStart: i.rentalDateStart,
        tEnd: i.rentalDateEnd,
        day: new moment(date).endOf("day").toDate().setMinutes(-1),
      });
    });
    const reducedDailyStock = ReduceStockByDay({
      useTurnaround,
      inventory,
      invoicesInRange: dayInvoices,
      startDate: new moment(date).startOf("day").toDate(),
    });
    reducedDailyStock?.map((item) => {
      const inList = invList?.find((i) => i?.inventoryId === item?.id);
      const isOverbooked = item?.isOverbooked;
      if (!isOverbooked || !inList) return;
      //   return {
      //     inventoryId: item?.id,
      //     overbookedAmt: 0,
      //     name: item?.name ?? "",
      //   };
      // return {
      //   inventoryId: item?.id,
      //   overbookedAmt: item?.stock - item?.unavailableStock,
      //   name: item?.name ?? "",
      // };

      const { inventoryId, overbookedAmt, ...rest } = inList;
      const payload = {
        inventoryId,
        overbookedAmt: (item?.stock - item?.unavailableStock) * -1,
        // (overbookedAmt + (item?.stock - item?.unavailableStock)) * -1 ?? 0,
        ...rest,
      };
      invList = invList?.filter((i) => i?.inventoryId !== item?.id);
      invList = [payload, ...invList];
    });

    const includeOnly = [];
    let list = {
      date: new moment(date).format("MMM Do YYYY"),
    };
    invList?.map((listItem) => {
      if (listItem?.overbookedAmt > 0) {
        includeOnly.push(listItem?.inventoryId);
      }
    });
    invList.map((li) => {
      list[li?.name] = li?.overbookedAmt ?? 0;
    });

    return list;
  });
};
