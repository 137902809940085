import { replace } from "./replace";
import moment from "moment";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { EmbeddedSettingsState } from "../adminEmbeddedContext";
/**
 * BLACKOUT DATES ACTIONS
 * ***
 * {
 *     orgId: "12345",
 *     checkoutQuestions: [],
 *     checkoutConfig: { snippetColor: null, snippetMarginTop: null },
 *     checkoutSettings: {
 *       type: "wishlist", // can also be 'order',
 *       allowOverbooked: false,
 *     },
 *     blackoutDates: [],
 *   }
 * **
 * *
 */

export const onDateChange = (state: EmbeddedSettingsState, props: any) => {
  console.log("onDateChange", props);
  // return replace(state, "blackoutDates", () => {
  //   return {
  //     snippetColor: props ? props : "#000",
  //     snippetMarginTop: state?.checkoutConfig?.snippetMarginTop,
  //   };
  // });
};
export const onDateClear = (
  state: EmbeddedSettingsState,
  props: { blackoutStart: any; blackoutEnd: any }
) => {};

export const removeBlackoutDate = (state: EmbeddedSettingsState, props: any) => {
  return replace(state, "blackoutDates", () => {
    const dates = state?.blackoutDates ?? [];
    return findDateByStartAndEnd(props.blackoutStart, props.blackoutEnd, dates);
  });
};
export const addBlackoutDate = (state: EmbeddedSettingsState, props: any) => {
  const data = {
    blackoutStart: props?.blackoutStart ?? null,
    blackoutEnd: props?.blackoutEnd ?? null,
    willCallAllowed: props?.willCallAllowed ?? false,
  };

  const invalid = !data.blackoutStart || !data.blackoutEnd;
  if (invalid) {
    notificationError("Missing dates");
    return replace(state, "blackoutDates", () => {
      return state?.blackoutDates ?? [];
    });
  }
  return replace(state, "blackoutDates", () => {
    const dates = state?.blackoutDates ?? [];
    return invalid ? dates : [data, ...dates];
  });
};

/**
 * Helper funcs
 * ***
 * **
 * *
 */
const findDateByStartAndEnd = (start: any, end: any, array: any) => {
  return array?.filter((i: any) => {
    // compare
    const sameStart = moment(start).isSame(i?.blackoutStart);
    const sameEnd = moment(end).isSame(i?.blackoutEnd);
    return !(sameStart && sameEnd);
  });
};
