import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
  INVOICE,
  QR_STATUS_IN_PROGRESS,
  QR_STATUS_NOT_STARTED,
  ROW_TYPE_SUBTOTAL,
} from "../modelConstants/modelConstants";
import Login from "../../../../domains/login/Login";
import { ConfigureQrItems } from "./configureQrItems";
import onlyUnique from "../../filters/filterOnlyUnique";
import { ConfigureEditQuickReportProps } from "./configureEditQuickReportProps";
import { DetermineInvoiceStatus } from "../../../services/hooks/helpers/qrItems/determineInvoiceStatus";

export const configureQuickReportProps = (
  selectedItems,
  type,
  currentInvoice
) => {
  if (type !== INVOICE)
    return {
      qrStatus: QR_STATUS_IN_PROGRESS,
      qrTotal: 0,
      qrItems: [],
    };

  let itemList = [];
  let itemTotal = 0;
  selectedItems.items &&
    selectedItems.items
      .filter((i) => i.rowType !== ROW_TYPE_SUBTOTAL)
      .map((item) => {
        const itemObj = {
          id: item.id,
          name: item.name,
          description: item.description,
          qtyRented: item.selectedQty,
          completed: 0,
          notes: null,
          hasIssue: false,
          issueDescription: null,
          bundleParentId: null,
          isBundleChild: false,
          bundleChildrenIds: [],
        };
        if (item.type === INV_BUNDLE_ITEM) {
          let childIds = [];
          item.bundleItems &&
            item.bundleItems.map((i) => {
              const {
                id,
                name,
                description,
                qtyRented,
                bundleParentId,
                categories,
                isBundleChild,
                ...rest
              } = itemObj;
              itemList.push({
                id: i[INV_BUNDLE_ITEM_ID],
                name: i[INV_BUNDLE_ITEM_NAME],
                description: i[INV_BUNDLE_ITEM_DESC],
                bundleParentId: item.id,
                isBundleChild: true,
                qtyRented: i[INV_BUNDLE_ITEM_QTY] * item.selectedQty,
                ...rest,
              });
              childIds.push(i[INV_BUNDLE_ITEM_ID]);
              itemTotal = itemTotal + 1;
            });
          const { bundleChildrenIds, ...rest } = itemObj;
          itemList.push({
            bundleChildrenIds: childIds.filter(onlyUnique),
            ...rest,
          });
        } else {
          itemList.push(itemObj);
          itemTotal = itemTotal + 1;
        }
      });
  const items = currentInvoice
    ? ConfigureEditQuickReportProps(currentInvoice, ConfigureQrItems(itemList))
    : ConfigureQrItems(itemList);
  return {
    qrStatus: DetermineInvoiceStatus(items),
    qrTotal: itemTotal,
    qrItems: items,
  };
};
