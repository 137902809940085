import React, { useState } from "react";
import { Button, Card, Col, Divider, Space } from "antd";
import StripeIntBtn from "./StripeIntBtn";
import HeadingTertiary from "../../../app/system-components/typography/headings/HeadingTertiary";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { useFirebase } from "react-redux-firebase";
import { COLOR_LIGHT_GRAY } from "../../../app/ui/colorConstants";
import { createStripeLoginLink } from "../../../app/services/cloud-functions/createStripeLoginLink";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { AreaChartOutlined } from "@ant-design/icons";

const StripeCard = ({ connectingStripe, setConnectingStripe, orgData }) => {
  const firebase = useFirebase();
  const [loading, setLoading] = useState(false);

  const configureDashboardBtn = (orgData) => {
    const showBtn = orgData?.stripeAccount ?? false;
    const complete = orgData?.stripeDetailsSubmitted ?? false;
    if (!showBtn || !complete) return;
    return (
      <Button
        // loading={loading}
        // disabled={loading}
        size={"small"}
        style={{ background: COLOR_LIGHT_GRAY }}
        type={"link"}
        onClick={async () => {
          // setLoading(true);
          // createStripeLoginLink({ firebase }, { orgId: orgData.id })
          //   .then(({ data }) => {
          //     const link = data?.url ?? null;
          //     if (link) {
          //       setLoading(false);
          window.open(
            `https://dashboard.stripe.com/${orgData.stripeAccount}/dashboard`,
            "_blank"
          );
          //     } else {
          //       setLoading(false);
          //       notificationError("Something went wrong");
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //     notificationError("Something went wrong");
          //     setLoading(false);
          //   });
        }}
      >
        My Payments Dashboard <AreaChartOutlined />
      </Button>
    );
  };

  return (
    <Col xs={12} sm={10} md={8} lg={8} xl={8} style={{ minWidth: "360px" }}>
      <Card size="small" style={{ height: "140px" }}>
        <StripeIntBtn
          connectingStripe={connectingStripe}
          setConnectingStripe={setConnectingStripe}
          orgData={orgData}
          firebase={firebase}
        />
        <HeadingTertiary
          text={"Adelie Payments"}
          style={{ display: "inline-flex", marginBottom: 0 }}
        />
        <br />
        <Space size={0}>
          <TextSubtitle text={"Powered by:"} />{" "}
          <img
            src="/images/stripe-logo.png"
            alt="stripe_logo"
            style={{ height: "20px" }}
          />
        </Space>
        <Divider style={{ margin: "16px 0" }} />
        {configureDashboardBtn(orgData)}
      </Card>
    </Col>
  );
};

export default StripeCard;
