import React, { useContext } from "react";
import { Spinner } from "../../app/ui/spinner";
import { Table } from "antd";
import { TrucksContext } from "./AdminTrucks";

const TruckTable = ({
  data,
  columns,
  loading,
  setContextMenu,
  contextMenu,
}) => {
  const configureContextMenu = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!contextMenu.visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setContextMenu({
          item: null,
          visible: false,
          x: 0,
          y: 0,
        });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setContextMenu({
      item: record,
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };
  return (
    <Table
      className="ManageTruckTable"
      style={{ margin: "12px" }}
      size="small"
      columns={columns}
      dataSource={data}
      rowKey={"id"}
      loading={{
        spinning: loading,
        indicator: Spinner,
        style: { marginTop: "80px" },
      }}
      pagination={{ defaultPageSize: 50 }}
      locale={{ emptyText: "No trucks" }}
      scroll={{ y: "calc(100vh - 176px)" }}
      onRow={(record) => {
        return {
          onContextMenu: (event) => {
            if (setContextMenu) {
              configureContextMenu(event, record);
            }
          },
          // onClick: (event) => {
          //   modal.loadData(record);
          //   modal.show();
          // },
        };
      }}
    />
  );
};

export default TruckTable;
