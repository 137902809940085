import React, { useContext } from "react";
import {
  Badge,
  Card,
  Col,
  Progress,
  Row,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from "antd";
import {
  COLOR_BLUE_0,
  COLOR_PURPLE_0,
} from "../../../../app/ui/colorConstants";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import SalesReportFilterTags from "../../sales/components/SalesReportFilterTags";
import { TaxReportContext } from "../TaxReport";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";

const { Text } = Typography;
const TaxStatisticCard = () => {
  const { state, actions } = useContext(TaxReportContext);
  return (
    <Card size={"small"} style={{ width: "100%" }}>
      <Row justify={"space-between"} align={"top"}>
        <Col>
          <Space direction={"vertical"}>
            <Space direction={"horizontal"} align={"start"}>
              {state.statCardData.isFiltered && (
                <Space>
                  <Statistic
                    prefix={"$"}
                    value={state.statCardData.filteredTaxableAmt}
                    precision={2}
                    valueStyle={{ color: COLOR_BLUE_0 }}
                  />
                  <Text style={{ fontSize: "20px" }}>/</Text>
                </Space>
              )}
              <Space>
                <Statistic
                  prefix={"$"}
                  value={state.statCardData.totalTaxableAmt}
                  precision={2}
                />
                <Tooltip
                  title={
                    "Total amount of taxable items and delivery fees across invoices"
                  }
                >
                  <CategoryTag text={"Taxable Amount"} />
                </Tooltip>
              </Space>
            </Space>
            <Space direction={"horizontal"} align={"start"}>
              {state.statCardData.isFiltered && (
                <Space>
                  <Statistic
                    prefix={"$"}
                    value={state.statCardData.filteredAmt}
                    precision={2}
                    valueStyle={{ color: COLOR_BLUE_0 }}
                  />
                  <Text style={{ fontSize: "20px" }}>/</Text>
                </Space>
              )}
              <Space>
                <Statistic
                  prefix={"$"}
                  value={state.statCardData.totalAmt}
                  precision={2}
                />
                <Tooltip title={"Total taxes across invoices"}>
                  <CategoryTag text={"Tax Collected"} />
                </Tooltip>
              </Space>
            </Space>
          </Space>
          <br />
          <TextSubtitle
            text={`From: ${state.dateRange?.start?.format(
              "MM/DD/yyyy"
            )} - ${state.dateRange?.end?.format("MM/DD/yyyy")}`}
          />
        </Col>
        {/*<Col>*/}
        {/*  {state.statCardData.isFiltered ? (*/}
        {/*    <Progress*/}
        {/*      type="circle"*/}
        {/*      percent={Math.round(state.statCardData.filteredPercentage)}*/}
        {/*      width={72}*/}
        {/*    />*/}
        {/*  ) : null}*/}
        {/*</Col>*/}
      </Row>
      <div>
        {state.statCardData.selectedRates?.map((r) => {
          return (
            <div style={{ marginRight: "12px", display: "inline-flex" }}>
              <Space direction={"horizontal"} size={0}>
                <Badge dot color={COLOR_BLUE_0} />
                <span>{r}</span>
              </Space>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default TaxStatisticCard;
