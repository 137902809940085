const UNDEFINED = "undefined";

export default function checkEmailSettings(emailSettingsSnap) {
    const data = emailSettingsSnap.data();

    if (typeof data !== UNDEFINED) {
        return {
        emailSubject: data.emailSubject
            ? data.emailSubject
            : "",
        emailFrom: data.emailFrom
            ? data.emailFrom
            : "",
        emailBody: data.emailBody
            ? data.emailBody
            : "",
        invoiceNotes: data.invoiceNotes
            ? data.invoiceNotes
            : "",
        emailSignature: data.emailSignature
            ? data.emailSignature
            : "",
        termsConditions: data.termsConditions
            ? data.termsConditions
            : "",
        invoiceCopyEmails: data.invoiceCopyEmails
            ? data.invoiceCopyEmails
            : [],
        };
    }
    return {
        emailSubject: "",
        emailFrom: "",
        emailBody: "",
        invoiceNotes: "",
        emailSignature: "",
        termsConditions: "",
        invoiceCopyEmails: [],
    };
}
