import notificationError from "../../../system-components/toasters/notificationError";
import {
  VENUE_CONTACT_NAME,
  VENUE_NAME,
  VENUE_ADDRESS,
  VENUE_CITY,
  VENUE_STATE,
  VENUE_ZIP,
  VENUE_FULL_ADDRESS_STR,
  VENUE_NOTES,
} from "../modelConstants/modelConstants";

export const configureVenue = () => {
  const createVenueAddressStr = (values) => {
    if (
      values[VENUE_ADDRESS] === "" ||
      values[VENUE_CITY] === "" ||
      values[VENUE_STATE] === "" ||
      values[VENUE_ZIP] === ""
    )
      return "";
    return `${values[VENUE_ADDRESS]}, ${values[VENUE_CITY]}, ${values[VENUE_STATE]} ${values[VENUE_ZIP]}`;
  };

  function newVenue(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not create venue", "");
    return {
      orgId: orgData.id,
      [VENUE_NAME]: values[VENUE_NAME] ? values[VENUE_NAME] : "",
      [VENUE_NOTES]: values[VENUE_NOTES] ? values[VENUE_NOTES] : "",
      [VENUE_CONTACT_NAME]: values[VENUE_CONTACT_NAME]
        ? values[VENUE_CONTACT_NAME]
        : "",
      [VENUE_ADDRESS]: values[VENUE_ADDRESS] ? values[VENUE_ADDRESS] : "",
      [VENUE_CITY]: values[VENUE_CITY] ? values[VENUE_CITY] : "",
      [VENUE_STATE]: values[VENUE_STATE] ? values[VENUE_STATE] : "",
      [VENUE_ZIP]: values[VENUE_ZIP] ? values[VENUE_ZIP] : "",
      [VENUE_FULL_ADDRESS_STR]: createVenueAddressStr(values),
      createdBy: createdById,
      createdOn: new Date(),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
      isActive: true,
    };
  }

  function editVenue(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not save venue", "");
    return {
      orgId: orgData.id,
      [VENUE_NAME]: values[VENUE_NAME] ? values[VENUE_NAME] : "",
      [VENUE_NOTES]: values[VENUE_NOTES] ? values[VENUE_NOTES] : "",
      [VENUE_CONTACT_NAME]: values[VENUE_CONTACT_NAME]
        ? values[VENUE_CONTACT_NAME]
        : "",
      [VENUE_ADDRESS]: values[VENUE_ADDRESS] ? values[VENUE_ADDRESS] : "",
      [VENUE_CITY]: values[VENUE_CITY] ? values[VENUE_CITY] : "",
      [VENUE_STATE]: values[VENUE_STATE] ? values[VENUE_STATE] : "",
      [VENUE_ZIP]: values[VENUE_ZIP] ? values[VENUE_ZIP] : "",
      [VENUE_FULL_ADDRESS_STR]: createVenueAddressStr(values),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
    };
  }

  function duplicateVenue(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not edit venue", "");
    const {
      id,
      venueName,
      createdOn,
      createdBy,
      updatedOn,
      updatedBy,
      ...rest
    } = values;
    return {
      venueName: `${venueName} Copy`,
      createdOn: new Date(),
      createdBy: createdById,
      updatedOn: new Date(),
      updatedBy: createdById,
      updateSource: "adelie",
      ...rest,
    };
  }

  return {
    newVenue: newVenue, // configure new venue
    editVenue: editVenue, // configure edited customer
    duplicateVenue: duplicateVenue, // duplicate venue
  };
};
