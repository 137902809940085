import { USERS_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import notificationError from "../../../system-components/toasters/notificationError";
import checkUser from "../../../utils/models/checkers/checkUser";

export function userQueries({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${USERS_COLLECTION}`);

  async function fetchOrgUsers() {
    let users = [];
    const snap = await ref.get();
    snap.forEach((s) => {
      users.push(checkUser(s));
    });
    return users;
  }
  return {
    fetchOrgUsers: fetchOrgUsers, // fetch all users in org
  };
}
