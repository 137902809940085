import React from "react";
import "../../App.less";
import { Button, PageHeader, Result, Tag } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const Reports = () => {
  return (
    <div>
      <PageHeader
        className="PageHeader"
        backIcon={false}
        title="Reports"
        subTitle={
          <Tag
            style={{
              borderColor: "transparent",
              color: "#fff",
              background: "#4427a3",
              margin: "0 4px",
              fontSize: "11px",
            }}
          >
            COMING SOON
          </Tag>
        }
      />
      <Result
        style={{ marginTop: "80px" }}
        icon={<SettingOutlined />}
        title="In development"
        subTitle="What reports would you want to see here? Send us any thoughts or ideas!"
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => {
              window.open(
                "mailto:info@adelielogistics.com?subject=Reporting%20Feedback"
              );
            }}
          >
            Send Feedback
          </Button>,
        ]}
      />
      ,
    </div>
  );
};

export default Reports;
