import React from "react";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";

const VenueContextMenu = ({
  handleEdit,
  handleDeactivate,
  handleReactivate,
  handleDuplicate,
  visible,
  item,
  x,
  y,
}) => {
  const { userData } = useSelector(authSelector);
  return (
    <div>
      {visible && userData && item && (
        <ul
          className="tableContextMenu"
          style={{ left: `${x}px`, top: `${y}px`, padding: "4px 0" }}
        >
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleEdit}
          >
            <TextBody text={"Edit/View Venue"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={item.isActive ? handleDeactivate : handleReactivate}
          >
            <TextBody text={item.isActive ? "Deactivate" : "Make Active"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleDuplicate}
          >
            <TextBody text={"Duplicate"} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default VenueContextMenu;
