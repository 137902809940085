/**
 * Create new stripe express account
 * Payload should be org id
 * Will redirect to page (specified in stripe) on completion
 * @param firebase
 * @param payload
 * @returns {Promise<HttpsCallableResult>}
 */
export async function createNewStripeConnect({ firebase }, payload) {
  const call = firebase.functions().httpsCallable("newConnectAccount");
  return await call(payload);
}
