/**
 * Generate new account Link
 * @param firebase
 * @param payload
 * @returns {Promise<HttpsCallableResult>}
 */
export async function newStripeAccountLink({ firebase }, payload) {
  const call = firebase.functions().httpsCallable("newAccountLink");
  return await call(payload);
}
