import React from "react";
import { Typography } from "antd";

const { Title } = Typography;
const HeadingTertiary = ({ text, ...props }) => {
  return (
    <Title level={3} {...props}>
      {text}
    </Title>
  );
};

export default HeadingTertiary;
