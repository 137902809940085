import notificationError from "../../../system-components/toasters/notificationError";
import { VENUE_COLLECTION } from "../../../utils/models/collections/collectionConstants";

export function venueWrites({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${VENUE_COLLECTION}`);

  async function createVenue(payload) {
    const newRef = firestore
      .collection(`${orgPrefix}${VENUE_COLLECTION}`)
      .doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function editVenue(id, payload) {
    return await ref.doc(id).update(payload);
  }
  async function deleteVenue(id) {
    return await ref.doc(id).delete();
  }

  return {
    createVenue: createVenue, // create venue
    editVenue: editVenue, // edit venue
    deleteVenue: deleteVenue, // delete venue
  };
}
