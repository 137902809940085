import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, Button, Input, PageHeader, Space, Spin } from "antd";
import CreateUserModal from "./components/CreateUserModal";
import UsersContextMenuWrapper from "./components/UsersContextMenuWrapper";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { userQueries } from "../../app/services/firestore/queries/userQueries";
import { adminUsersColumns } from "./AdminUsersColumns";
import { firstNameSorter } from "../../app/utils/sorters/firstNameSorter";

const AdminUsers = ({ history }) => {
  const { fsOrgPrefix } = useSelector(authSelector);
  const [toggleCreateModal, setToggleCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [keywords, setKeywords] = useState(null);

  const firestore = useFirestore();

  useEffect(() => {
    fsOrgPrefix && loadUsers();
  }, [fsOrgPrefix]);

  const loadUsers = () => {
    fsOrgPrefix &&
      userQueries({ firestore }, fsOrgPrefix)
        .fetchOrgUsers()
        .then((orgUsers) => {
          setLoading(false);
          setUsers(firstNameSorter(orgUsers));
        });
  };

  const handleCancel = () => {
    setToggleCreateModal(false);
  };
  const handleComplete = () => {
    setLoading(true);
    setToggleCreateModal(false);
    loadUsers();
  };
  const onEditComplete = () => {
    setLoading(true);
    loadUsers();
  };

  const configureUsers = () => {
    if (!keywords) return users;
    const lcKeys = keywords.toLowerCase();
    return users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(lcKeys) ||
        user.lastName.toLowerCase().includes(lcKeys) ||
        user.email.toLowerCase().includes(lcKeys)
    );
  };
  const handleUsersClicked = () => {};
  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          backIcon={false}
          title="User Management"
          extra={[
            <Space size={12}>
              <Input.Search
                placeholder="Search users"
                allowClear={true}
                onSearch={(value) => setKeywords(value)}
                style={{ width: "250px" }}
              />
              <Button type="primary" onClick={() => setToggleCreateModal(true)}>
                Create User
              </Button>
            </Space>,
          ]}
        />
      </Affix>
      <div className="list-margin-top" style={{ marginTop: "68px" }}>
        <UsersContextMenuWrapper
          history={history}
          data={configureUsers()}
          onEditComplete={onEditComplete}
          loading={loading}
          onDeleteComplete={loadUsers}
        />
      </div>
      <CreateUserModal
        visible={toggleCreateModal}
        onCancel={handleCancel}
        onComplete={handleComplete}
      />
    </div>
  );
};

export default AdminUsers;
