import React from "react";
import { Card } from "antd";
import NumberFormat from "react-number-format";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { COLOR_BLUE_0 } from "../../../../app/ui/colorConstants";

const TaxColTooltip = ({ payload }) => {
  const configureAmt = () => {
    if (!payload) return 0;
    return payload?.[0]?.payload?.total ?? 0;
  };
  const configureName = () => {
    if (!payload) return "";
    return payload?.[0]?.payload?.name ?? "";
  };
  const amt = configureAmt();
  return (
    <Card size={"small"}>
      <TextBody text={configureName()} />
      <br />
      <NumberFormat
        value={amt.toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        style={{ color: COLOR_BLUE_0 }}
      />
    </Card>
  );
};

export default TaxColTooltip;
