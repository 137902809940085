import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "../../../../app/ui/spinner";
import { Card, Progress, Space, Table, Input } from "antd";
import { UsageReportContext } from "../UsageReport";
import {
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_FULL_SHIPPING_ADDRESS_STR,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import SelectViewInterval from "./SelectViewInterval";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../../app/ui/colorConstants";
import { ConvertRateRangeInterval } from "../../../../app/services/hooks/helpers/reports/convertRateRangeInterval";
import FilterByCategory from "./FilterByCategory";
import { i18n } from "../../../../i18n";

const UsageTable = () => {
  const { state, actions } = useContext(UsageReportContext);
  const [keywords, setKeywords] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const data = state.chartData?.filter((item) =>
      `${item?.name}`.toLowerCase().includes(keywords)
    );
    setFilteredData(data);
  }, [state.chartData, keywords]);

  const convertUnits = (unitCount) => {
    return ConvertRateRangeInterval({
      unitCount,
      unitInterval: "hour",
      convertToInterval: state.viewIntervalRange,
    });
  };
  const columns = [
    {
      title: "Item",
      dataIndex: "name",
    },
    {
      title: "Usage",
      dataIndex: "usage",
      sorter: (a, b) => a?.usage - b?.usage,
      defaultSortOrder: "descend",
      width: 200,
      render: (item, record) => {
        return (
          <Space size={20}>
            <Progress
              steps={5}
              showInfo={false}
              strokeColor={COLOR_GREEN_0}
              percent={record?.usagePercent * 100}
              success={{
                strokeColor: COLOR_GREEN_0,
              }}
            />
            <TextSubtitle
              text={`${convertUnits(record?.usage).toLocaleString("us", {
                maximumFractionDigits: 2,
              })}`}
            />
          </Space>
        );
      },
    },
    {
      title: "Overused",
      dataIndex: "overflow",
      sorter: (a, b) => a?.overflow - b?.overflow,
      width: 200,
      render: (item, record) => {
        return (
          <Space size={20}>
            <Progress
              steps={5}
              showInfo={false}
              strokeColor={COLOR_RED_0}
              percent={record?.overflowPercent * 100}
            />
            <TextSubtitle
              text={`${convertUnits(record?.overflow).toLocaleString("us", {
                maximumFractionDigits: 2,
              })}`}
            />
          </Space>
        );
      },
    },
    {
      title: "Unused Rental Potential",
      dataIndex: "maxPotential",
      sorter: (a, b) => a?.maxPotential - b?.maxPotential,
      width: 200,
      render: (item, record) => {
        return (
          <Space size={20}>
            <Progress
              steps={5}
              showInfo={false}
              strokeColor={COLOR_YELLOW_0}
              percent={record?.maxPotentialPercent * 100}
            />
            <TextSubtitle
              text={`${convertUnits(record?.maxPotential).toLocaleString("us", {
                maximumFractionDigits: 2,
              })}`}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <Card
      size={"small"}
      title={"Usage"}
      extra={
        <Space>
          <Input.Search
            onChange={(e) => setKeywords(e.target.value?.toLowerCase())}
            placeholder={ i18n('inventory.search') }
            style={{ width: "250px" }}
          />
          <FilterByCategory />
          View by: <SelectViewInterval />
        </Space>
      }
    >
      <Table
        className="InventoryUsageTable"
        style={{ margin: "12px", height: "800px" }}
        size="small"
        columns={columns}
        dataSource={filteredData}
        rowKey={"id"}
        pagination={{ defaultPageSize: 50 }}
        locale={{ emptyText: "No data" }}
        scroll={{ y: "800px" }}
      />
    </Card>
  );
};

export default UsageTable;
