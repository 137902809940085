import React, { FC, useContext, useRef } from "react";
import { Input } from "antd";
import {
  AdminEmbeddedContext,
  EmbeddedCheckoutQuestion,
} from "../adminEmbeddedContext";

const QuestionInput: FC<{ item: EmbeddedCheckoutQuestion }> = ({ item }) => {
  const { actions } = useContext(AdminEmbeddedContext);
  const InputRef = useRef(null);
  return (
    <Input
      ref={InputRef}
      key={`KEY_${item.index}`}
      value={item.question}
      onChange={(v) => {
        actions.checkoutQuestions.update({
          index: item.index,
          question: v.target.value,
          isRequired: item.isRequired,
        });
      }}
    />
  );
};

export default QuestionInput;
