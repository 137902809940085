import React, { useContext } from "react";
import { UsageReportContext } from "../UsageReport";
import {
  LineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import OverbookedTooltip from "./OverbookedTooltip";
import { Card } from "antd";

const OverbookedChart = () => {
  const { state, actions } = useContext(UsageReportContext);
  const getDataKeys = () => {
    if (!state.overbookedData?.[0]) return;
    let list = [];
    for (const [k, v] of Object.entries(state.overbookedData?.[0])) {
      if (k !== "date") {
        list.push(k);
      }
    }
    return list?.map((item) => {
      return (
        <Line
          name={item}
          key={item}
          type="monotone"
          dataKey={item}
          stroke={COLOR_RED_0}
          activeDot={{ r: 4 }}
        />
      );
    });
  };
  const formatYAxis = (v) => {
    return v.toLocaleString("us", { maximumFractionDigits: 2 });
  };
  return (
    <Card size={"small"} title={"Overbooked Items"}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={state.overbookedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/*<CartesianGrid strokeDasharray="3 3" />*/}
          <XAxis dataKey="date" />
          <YAxis
            tickFormatter={(v) => `${formatYAxis(v, state.viewIntervalRange)}`}
          />
          <Tooltip content={OverbookedTooltip} />
          {getDataKeys()}
          )}
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default OverbookedChart;
