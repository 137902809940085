/**
 * ALL INVOICES MUST BE RUN THROUGH CHECKER BEFORE
 * ALL FUNCS ASSUME CURRENCY IS IN DOLLARS (NOT CENTS)
 */
import { CheckSelectedItem } from "../models/checkers/checkSelectedItem";
import Nifty from "../Nifty";

export const GetTaxData = (invoices) => {
  let sumTotalTaxableAmt = 0;
  const data = invoices?.map((i) => {
    // get taxable items from selectedItems
    const taxableItems = SumTaxableSelectedItems(i);

    // get delivery fee amount if taxable
    const taxableDeliveryAmt = GetTaxableDeliveryFeeAmt(i);

    // get the subtotal without discounts applied for creating the ratio
    const subtotalWithoutDiscount = GetSubtotalWithoutDiscount(i);

    // get discount amount
    const discount = GetDiscountAmt(i);

    // get the tax ratio from items, delivery fee and divide by subtotal without discounts
    const taxRatio =
      (taxableItems.totalTaxableAmt + taxableDeliveryAmt) /
      subtotalWithoutDiscount;

    // if a discount exists, prorate the discount amount
    const proratedDiscountAmt = prorateTaxableDiscount(taxRatio, discount);

    // output final total taxable amount
    const totalTaxableAmt =
      taxableItems.totalTaxableAmt + taxableDeliveryAmt - proratedDiscountAmt;

    sumTotalTaxableAmt = sumTotalTaxableAmt + totalTaxableAmt;

    return {
      id: i?.id,
      totalTaxableAmt,
      proratedDiscountAmt,
      taxRatio,
      discount,
      taxableItems,
      taxableDeliveryAmt,
      taxAmt: i?.taxAmt,

      invoice: i,
    };
  });
  return { sumTotalTaxableAmt, data };
};

export const SumTaxableSelectedItems = (invoice) => {
  const selectedItems = invoice?.selectedItems?.items ?? [];
  let totalTaxableAmt = 0;
  let taxableSelectedItems = [];
  ForEachSelectedItem(selectedItems, (item) => {
    if (!item.selectedTaxable) return;
    taxableSelectedItems.push(item);
    return (totalTaxableAmt = totalTaxableAmt + item.rowTotal);
  });
  return { totalTaxableAmt, taxableSelectedItems };
};

export const ForEachSelectedItem = (selectedItems, callbackFn) => {
  selectedItems?.map((i) => {
    callbackFn(CheckSelectedItem(i));
  });
};

export const GetDiscountAmt = (invoice) => {
  if (invoice?.totalDiscountType === "number")
    return invoice?.totalDiscountNumber ?? 0;
  if (invoice?.totalDiscountType === "percent") {
    const p = invoice?.totalDiscountPercent ?? 0;
    return Nifty.RoundToTwoDecimals((invoice?.subtotal / (1 - p)) * p);
  } else {
    return 0;
  }
};

export const GetSubtotalWithoutDiscount = (invoice) => {
  const discount = GetDiscountAmt(invoice);
  if (discount === 0) return invoice.subtotal;
  return invoice.subtotal + discount;
};

export const GetTaxableDeliveryFeeAmt = (invoice) => {
  return invoice?.deliveryFeeTaxable ? invoice?.deliveryFee : 0;
};

const prorateTaxableDiscount = (taxableRatio, discountAmt) => {
  const amt = discountAmt * taxableRatio;
  return isNaN(amt) ? 0 : Nifty.RoundToTwoDecimals(amt);
};
