import React, { useContext } from "react";
import { Card, Input, Space, Switch } from "antd";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

export const EmbeddedDaysOfTheWeek = () => {
  const { actions, state } = useContext(AdminEmbeddedContext);

  return (
    <Card
      size={"small"}
      title={"Days of Operation"}
      style={{ marginTop: "24px" }}
    >
      <Space direction="vertical">
        <div>
          <TextSubtitle text={"CLOSED DAY OF OPERATION SELECTED FOR PICKUP OR DROPOFF MESSAGE"} />
          <br />
          <Input
            value={state.closedDateSelectedMessage}
            style={{ width: 400 }}
            onChange={(v) =>
              actions.setClosedDateSelectedMessage(v.target.value)
            }
          />
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Sunday}
            onChange={(props) =>
              actions.setDayOfOperation("Sunday", props.valueOf())
            }
          />{" "}
          Sunday
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Monday}
            onChange={(props) =>
              actions.setDayOfOperation("Monday", props.valueOf())
            }
          />{" "}
          Monday
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Tuesday}
            onChange={(props) =>
              actions.setDayOfOperation("Tuesday", props.valueOf())
            }
          />{" "}
          Tuesday
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Wednesday}
            onChange={(props) =>
              actions.setDayOfOperation("Wednesday", props.valueOf())
            }
          />{" "}
          Wednesday
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Thursday}
            onChange={(props) =>
              actions.setDayOfOperation("Thursday", props.valueOf())
            }
          />{" "}
          Thursday
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Friday}
            onChange={(props) =>
              actions.setDayOfOperation("Friday", props.valueOf())
            }
          />{" "}
          Friday
        </div>
        <div>
          <Switch
            checked={state.daysOfOperation.Saturday}
            onChange={(props) =>
              actions.setDayOfOperation("Saturday", props.valueOf())
            }
          />{" "}
          Saturday
        </div>
      </Space>
    </Card>
  );
};
