import React, { useContext } from "react";
import { Card, Col, Progress, Row } from "antd";
import { DepreciationReportContext } from "../DepreciationReport";

const CategorizedBreakout = () => {
  const { state, actions } = useContext(DepreciationReportContext);
  return (
    <Card
      size={"small"}
      title={"Category Breakout"}
      style={{ marginTop: "12px" }}
    >
      {state.categorizedBreakout?.map((b) => {
        return (
          <Card
            size={"small"}
            bodyStyle={{ padding: "4px" }}
            style={{ marginBottom: "12px" }}
          >
            <Row justify={"space-between"}>
              <Col style={{ fontWeight: "bold" }}>{b?.name}</Col>
              <Col>
                <span>
                  {b?.currentValue} / {b?.total}{" "}
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={23}>
                <Progress
                  strokeColor={b?.color}
                  size={"small"}
                  showInfo={true}
                  percent={(b?.percent * 100).toLocaleString("us", {
                    maximumFractionDigits: 0,
                  })}
                />
              </Col>
            </Row>
          </Card>
        );
      })}
    </Card>
  );
};

export default CategorizedBreakout;
