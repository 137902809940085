import React from "react";
import { Table } from "antd";
import { Spinner } from "../../../app/ui/spinner";

const AdminUsersTable = ({
  columns,
  data,
  setContextMenu,
  contextMenu,
  loading,
}) => {
  const configureContextMenu = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!contextMenu.visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setContextMenu({
          user: null,
          visible: false,
          x: 0,
          y: 0,
        });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setContextMenu({
      user: record,
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };
  return (
    <Table
      style={{ margin: "12px" }}
      size="small"
      columns={columns}
      dataSource={data}
      rowKey={"id"}
      loading={{
        spinning: loading,
        indicator: Spinner,
        style: { marginTop: "80px" },
      }}
      pagination={{ defaultPageSize: 50 }}
      locale={{ emptyText: "No users" }}
      scroll={{ y: "calc(100vh - 176px)" }}
      onRow={(record) => {
        return {
          onContextMenu: (event) => {
            if (setContextMenu) {
              configureContextMenu(event, record);
            }
          },
        };
      }}
    />
  );
};

export default AdminUsersTable;
