import { INV_SALE_ITEM } from "../../../../utils/models/modelConstants/modelConstants";

export const IncludeInDepreciation = (inventoryItem) => {
  if (!inventoryItem) return false;
  if (inventoryItem?.type === INV_SALE_ITEM) return false;

  const depreciation = inventoryItem?.depreciation;
  if (!depreciation) return false;
  return !(
    !depreciation?.initialValue ||
    !depreciation?.initialDate ||
    !depreciation?.depreciationYears
  );
};
