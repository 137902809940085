import React, { useContext, useEffect, useState } from "react";
import { SalesReportContext } from "../../sales/SalesReport";
import moment from "moment";
import { DatePicker, Radio, Space } from "antd";
import { TaxReportContext } from "../TaxReport";

const { RangePicker } = DatePicker;

const TaxDateRangePicker = () => {
  const { state, actions } = useContext(TaxReportContext);
  const [diff, setDiff] = useState(7);

  useEffect(() => {
    let a = moment(state.dateRange?.start);
    let b = moment(state.dateRange?.end);
    const days = a.diff(b, "days");
    setDiff(days * -1);
  }, [state.dateRange]);

  return (
    <div style={{ textAlign: "left", width: "100%" }}>
      <Space align={"start"} size={0}>
        <div>
          <RangePicker
            value={[state.dateRange?.start, state.dateRange?.end]}
            defaultValue={[state.dateRange?.start, state.dateRange?.end]}
            style={{ width: "300px", marginBottom: "12px" }}
            onChange={actions.changeDateRange}
            format={(d) => d.format("MMM Do YYYY")}
          />
          <br />
          <Radio.Group
            value={diff}
            onChange={(v) => {
              console.log(v);
              actions.setDateRange({
                start: moment().subtract(v.target.value, "days").startOf("day"),
                end: moment().endOf("day"),
              });
            }}
            defaultValue={7}
          >
            <Radio.Button value={7}>Last 7 Days</Radio.Button>
            <Radio.Button value={30}>Last 30 Days</Radio.Button>
            <Radio.Button value={90}>Last 90 Days</Radio.Button>
          </Radio.Group>
        </div>
        <Radio.Group
          value={"accrual"}
          // onChange={}
          defaultValue={"accrual"}
        >
          <Radio.Button value={"accrual"}>Accrual</Radio.Button>
          <Radio.Button disabled={true} value={"cash"}>
            Cash (Coming Soon)
          </Radio.Button>
        </Radio.Group>
      </Space>
    </div>
  );
};

export default TaxDateRangePicker;
