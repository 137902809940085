import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { Button, Col, Form, Input, Layout, Row } from "antd";
import { Link } from "react-router-dom";
import { ROUTE_LOGIN } from "../../app/routes";
import { resetPasswordEmail } from "./resetPasswordEmail";

const { Content } = Layout;
const ForgotPasswordPage = () => {
  const [busy, setBusy] = useState(false);
  const firebase = useFirebase();
  const [form] = Form.useForm();

  const onFinish = async () => {
    const values = form.getFieldsValue();
    setBusy(true);
    await resetPasswordEmail({ firebase }, values.email);
    setBusy(false);
  };

  const checkEmail = (rule, value) => {
    const mailFormat = emailRegexCheck;

    if (value == null) {
      return Promise.reject("Enter an email address");
    }
    if (value.match(mailFormat)) {
      return Promise.resolve();
    }
    return Promise.reject("Must enter a valid email address");
  };

  return (
    <Layout>
      <Content>
        <Row
          type="flex"
          justify="center"
          style={{
            backgroundColor: "#FFFFFF",
            height: "100vh",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              marginTop: "64px",
              padding: "24px",
            }}
          >
            <img
              src="/images/adelie-logo.svg"
              alt="adelie-logo"
              style={{
                height: "22px",
                margin: "0em auto auto auto",
                display: "block",
              }}
            />
            <p justify="center" align="middle" style={{ marginTop: "8px" }}>
              Forgot your password?
            </p>
            <Form
              onFinish={onFinish}
              layout="vertical"
              hideRequiredMark
              form={form}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[{ validator: checkEmail }]}
              >
                <Input
                  size="'large"
                  placeholder="email"
                  style={{ height: "40px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ margin: ".6em 0em 0em" }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={busy}
                  block
                >
                  Send Password Reset Email
                </Button>
              </Form.Item>
              <Link to={ROUTE_LOGIN}>
                <Button type="link" size="small" block>
                  Back to login
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ForgotPasswordPage;

export const emailRegexCheck =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gm;
