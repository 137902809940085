import { EmbeddedSettingsState } from "../adminEmbeddedContext";

export const replace = (
  state: EmbeddedSettingsState,
  prop: string,
  getNewValue: () => any
): EmbeddedSettingsState => {
  const data = getNewValue();
  return {
    ...state,
    [prop]: data,
  };
};
