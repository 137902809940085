import moment from "moment";
export const EnumerateBetweenDates = (startDate, endDate) => {
  let currDate = moment(startDate).startOf("day");
  let lastDate = moment(endDate).startOf("day");
  let dates = [currDate.toDate()];

  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate());
  }
  return dates;
};
