import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import TruckTable from "./TruckTable";
import TruckContextMenu from "./TruckContextMenu";
import { truckColumns } from "./truckColumns";
import { TrucksContext } from "./AdminTrucks";

const TruckContextMenuWrapper = ({ loading, data, refreshTable }) => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const [contextMenu, setContextMenu] = useState({
    item: null,
    visible: false,
    x: 0,
    y: 0,
  });
  const modal = useContext(TrucksContext);

  const [saving, setSaving] = useState(false);
  const firestore = useFirestore();
  const history = useHistory();
  const configureMenuOpts = (context) => {
    setContextMenu({
      item: context.item,
      visible: context.visible,
      x: context.x,
      y: context.y,
    });
  };
  return (
    <div>
      {userData && orgData && (
        <TruckTable
          data={data}
          loading={loading}
          setContextMenu={configureMenuOpts}
          contextMenu={contextMenu}
          columns={truckColumns({
            onTitleClick: (item) => {
              modal.loadData(item);
              modal.show();
            },
          })}
        />
      )}
      <TruckContextMenu
        item={contextMenu.item}
        visible={contextMenu.visible}
        x={contextMenu.x}
        y={contextMenu.y}
        history={history}
        refreshTable={refreshTable}
      />
    </div>
  );
};

export default TruckContextMenuWrapper;
