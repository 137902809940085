import React, { useEffect, useRef, useState } from "react";
import { useFirestoreFetch } from "../../../app/services/hooks/fetch/useFirestoreFetch";
import { PaymentQueries } from "../../../app/services/firestore/queries/paymentQueries";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { Card } from "antd";
import PaymentList from "../../sharedComponents/payments/PaymentList";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { CheckPayment } from "../../../app/utils/models/checkers/checkPayment";

import { useInvoicePaymentHistory } from "../../../app/services/hooks/useInvoicePaymentHistory";
const { useFirebase } = require("react-redux-firebase");


const InvoicePaymentHistory = ({ invoiceId }) => {
  const firebase = useFirebase();
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const [payments, setPayments] = useState([])
  const [fetching, setFetching] = useState(false)
  const listener = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      listener.current = firebase
        .firestore()
        .collection("orgs")
        .doc(orgData.id)
        .collection("payments")
        .where("invoicesQueryHook", "array-contains", invoiceId)
        .orderBy("paymentDate", "desc")
        .onSnapshot((snapshot) => {

          let data = [];
          snapshot.docs.forEach((s) => {
            data.push(CheckPayment(s, orgData.orgTimezone));
          });
          setPayments(data);
        })
    };

    fetchData();

    return () => {
      // This line only evaluates to true after the componentWillUnmount happens 
      const ref = listener.current
      ref()
    }
  }, []);  // dont remove dependency array.

  return (
    <div>
      <Card
        // style={{ margin: "0 20px 20px 20px" }}
        size="small"
        title={
          <TextBody style={{ fontWeight: "bold" }} text={"Payment History"} />
        }
      >
        <PaymentList fetching={false} data={payments} invoiceId={invoiceId} />
      </Card>
    </div>
  );
};

export default InvoicePaymentHistory;
