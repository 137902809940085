export const ConvertRateRangeInterval = ({
  unitCount,
  unitInterval,
  convertToInterval,
}) => {
  const currentIntervalValid = validateUsageInterval(unitInterval);
  const toIntervalValid = validateUsageInterval(convertToInterval);
  if (currentIntervalValid && toIntervalValid) {
    return hoursToInterval(
      convertUsageToHours(unitCount, unitInterval),
      convertToInterval
    );
  } else {
    console.log("Invalid usage intervals", unitInterval, convertToInterval);
    return 0;
  }
};

const validateUsageInterval = (interval) => {
  return !!(
    interval &&
    (interval === "hour" ||
      interval === "day" ||
      interval === "week" ||
      interval === "month")
  );
};

const convertUsageToHours = (currentIntervalCount, currentInterval) => {
  if (!currentIntervalCount) return 0;
  switch (currentInterval) {
    case "hour":
      return currentIntervalCount;
    case "day":
      return currentIntervalCount * 24;
    case "week":
      return currentIntervalCount * 24 * 7;
    case "month":
      return currentIntervalCount * 24 * 7 * 4;
    default:
      return currentIntervalCount;
  }
};

const hoursToInterval = (hours, toInterval) => {
  if (!hours) return 0;
  switch (toInterval) {
    case "hour":
      return hours;
    case "day":
      return hours / 24;
    case "week":
      return hours / 24 / 7;
    case "month":
      return hours / 24 / 7 / 4;
    default:
      return hours;
  }
};
