import React, { useContext } from "react";
import { Select } from "antd";
import { UsageReportContext } from "../UsageReport";

const SelectViewInterval = () => {
  const { state, actions } = useContext(UsageReportContext);
  return (
    <Select
      onSelect={(i) => actions.setViewIntervalRange(i)}
      style={{ width: "100%" }}
      value={state.viewIntervalRange}
    >
      <Select.Option value={"hour"} key={"hour"}>
        Hours
      </Select.Option>
      <Select.Option value={"day"} key={"day"}>
        Days
      </Select.Option>
      <Select.Option value={"week"} key={"week"}>
        Weeks
      </Select.Option>
      <Select.Option value={"month"} key={"month"}>
        Months
      </Select.Option>
    </Select>
  );
};

export default SelectViewInterval;
