import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";

export const qboEndpoint = async (endpoint) => {
  fetch(endpoint).then((res) => {
    const { status } = res;
    if (status && status === 200) {
      notificationConfirm("Quickbooks account linked", "");
    }
    if (status && status === 500) {
      notificationError("Could not connect to Quickbooks", "");
    }
    console.log("Callback complete", res);
  });
};
