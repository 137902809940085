import TextBody from "../../../../app/system-components/typography/text/TextBody";
import convertTimeToDayString from "../../../../app/utils/time/convertTimeToDayString";
import { getColorForPercentage } from "../../../../app/ui/colorMap";

export const TrucksDepreciationColumns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Service Date",
    dataIndex: "serviceDate",
    render: (date, record) => {
      const str = date && convertTimeToDayString(date);
      return <TextBody text={str} />;
    },
  },
  {
    title: "Depreciated Value",
    dataIndex: "currentDepreciation",
    width: 160,
    sorter: (a, b) =>
      a?.currentDepreciation?.depreciatedPercentage -
      b?.currentDepreciation?.depreciatedPercentage,
    render: (depreciation, record) => {
      if (!depreciation) console.log(depreciation);
      return (
        <div>
          {Math.sign(depreciation?.depreciatedValue) === 1 ? (
            <TextBody
              text={`$${depreciation?.depreciatedValue.toLocaleString("us", {
                maximumFractionDigits: 2,
              })} (${Math.round(depreciation?.depreciatedPercentage * 100)}%)`}
              style={{
                color: getColorForPercentage(
                  depreciation?.depreciatedPercentage
                ),
              }}
            />
          ) : (
            <TextBody
              text={`$0`}
              style={{
                color: getColorForPercentage(0),
              }}
            />
          )}
        </div>
      );
    },
  },
];
