import moment from "moment";
export const GetUsageProRateWindow = ({
  invoiceStart,
  invoiceEnd,
  selectedStart,
  selectedEnd,
}) => {
  // selected
  const start = new moment(invoiceStart);
  const end = new moment(invoiceEnd);
  const cutoffStart = new moment(selectedStart);
  const cutoffEnd = new moment(selectedEnd);

  const invoiceHours = end.diff(start, "hours");
  let eligibleMax = cutoffEnd.diff(cutoffStart, "hours");
  let eligible = invoiceHours;

  // if negative, is before cutoff. positive is after
  const startDiff = start.diff(cutoffStart, "hours");
  if (startDiff < 0) {
    // add to eligible total
    eligible = eligible + startDiff;
  }
  const endDiff = end.diff(cutoffEnd, "hours");
  if (endDiff > 0) {
    eligible = eligible - endDiff;
  }
  return eligible;
};
