export const CalcDepreciation = ({
  initialValue,
  initialDate,
  depreciationYears,
}) => {
  if (!initialValue || !initialDate || !depreciationYears) return 0;
  let today = new Date();
  let currentDate = initialDate.toDate();
  let futureDate = initialDate.toDate();
  futureDate.setFullYear(futureDate.getFullYear() + depreciationYears);
  let q = Math.abs(today - currentDate);
  let d = Math.abs(futureDate - currentDate);
  const depreciatedAmt = Math.round((q / d) * 100) / 100;
  const depreciatedPercentage = 1 - depreciatedAmt;
  const depreciatedValue = Math.round(depreciatedPercentage * initialValue);
  return {
    depreciatedValue,
    depreciatedPercentage,
    initialValue,
  };
};
