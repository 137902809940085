import { ROW_TYPE_SUBTOTAL } from "../../../../utils/models/modelConstants/modelConstants";
import { ConvertRateRangeInterval } from "./convertRateRangeInterval";

export const FormatForUsageChart = ({ item, inventoryListItem, maxHours }) => {
  // ignore
  const ignoreSubtotalRow = item?.rowType === ROW_TYPE_SUBTOTAL;
  const itemMatch = item?.id === inventoryListItem?.inventoryId;
  if (ignoreSubtotalRow || !itemMatch) return inventoryListItem; // ignore if subtotal row or not item

  const duration = item?.selectedDuration ?? 0;
  const rateRange = item?.selectedRateMeta?.rateRange;
  const qty = item?.selectedQty ?? 0;
  const rawEligibleHours = ConvertRateRangeInterval({
    unitCount: duration,
    unitInterval: rateRange,
    convertToInterval: "hour",
  });
  const baseHours = maxHours > rawEligibleHours ? rawEligibleHours : maxHours;
  const totalBaseHours = baseHours * qty;
  const overflow = totalBaseHours - maxHours;
  return {
    eligibleHours: totalBaseHours,
  };
};
