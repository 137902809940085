import React, { useContext, useState } from "react";
import { Input } from "antd";
import { DepreciationReportContext } from "../DepreciationReport";

const DepreciationSearch = () => {
  const { state, actions } = useContext(DepreciationReportContext);
  const ph =
    state?.currentTab === "inventory" ? "Search inventory" : "Search trucks";
  return (
    <Input.Search
      value={state.searchStr}
      style={{ width: "240px" }}
      onChange={(v) => actions.handleSearch(v.target.value)}
      placeholder={ph}
    ></Input.Search>
  );
};

export default DepreciationSearch;
