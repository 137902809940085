import React, { useContext, useEffect, useState } from "react";
import { SalesReportContext } from "../../sales/SalesReport";
import moment from "moment";
import { Radio, DatePicker } from "antd";
import { UsageReportContext } from "../UsageReport";

const { RangePicker } = DatePicker;
const UsageRangePicker = () => {
  const { state, actions } = useContext(UsageReportContext);
  const [diff, setDiff] = useState(6);

  useEffect(() => {
    let a = moment(state.dateRange?.start);
    let b = moment(state.dateRange?.end);
    const days = a.diff(b, "days");
    setDiff(days * -1);
  }, [state.dateRange]);

  return (
    <div style={{ textAlign: "left", width: "100%" }}>
      <RangePicker
        value={[state.dateRange?.start, state.dateRange?.end]}
        defaultValue={[state.dateRange?.start, state.dateRange?.end]}
        style={{ width: "300px", marginBottom: "12px" }}
        onChange={actions.changeDateRange}
        format={(d) => d.format("MMM Do YYYY")}
      />
      <br />
      <Radio.Group
        value={diff}
        onChange={(v) => {
          console.log(v);
          const removedDay = v.target.value;
          actions.setDateRange({
            start: moment().subtract(removedDay, "days").startOf("day"),
            end: moment().endOf("day"),
          });
        }}
        defaultValue={6}
      >
        <Radio.Button value={6}>Last 7 Days</Radio.Button>
        <Radio.Button value={29}>Last 30 Days</Radio.Button>
        <Radio.Button value={89}>Last 90 Days</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default UsageRangePicker;
