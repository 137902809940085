import { CUSTOMER_DISPLAY_NAME } from "../../../../utils/models/modelConstants/modelConstants";
import { sortByNum } from "../../../../utils/sorters/sortBy";

export const GetInventoryFromInvoices = (invoiceList) => {

  let dayItems = [];
  invoiceList &&
    invoiceList.map((inv) => {
      const { qrItems, qrStatus, qrTotal, ...rest } = inv;
      qrItems &&
        qrItems.map((item) => {

          if (!item.bundleParentId && item.bundleChildrenIds.length !== 0) {
            return;
          }
          const exists = dayItems.find((di, index) => {
            return di.id === item.id;
          });



          if (exists) {
            const {
              id,
              qtyRented,
              completed,
              hasIssue,
              name,
              description,
              ...rest
            } = exists;
            const obj = {
              id: id,
              name: name,
              description: description,
              qtyRented: qtyRented + item.qtyRented,
              completed: completed + item.completed,
              completedPercent:
                (completed + item.completed) / (qtyRented + item.qtyRented),
              hasIssue: hasIssue ? true : item.hasIssue,
              itemList: [
                {
                  invoiceId: inv.id,
                  invoiceNumber: inv.invoiceNumber,
                  customer: inv.customer[CUSTOMER_DISPLAY_NAME],
                  ...item,
                },
                ...exists.itemList,
              ],
            };
            const items = dayItems.filter((i) => i.id !== id);
            dayItems = [obj, ...items];
          } else {
            const { id, qtyRented, completed, hasIssue, name, description } =
              item;
            dayItems.push({
              id: id,
              name: name,
              description: description,
              qtyRented: qtyRented,
              completed: completed,
              hasIssue: hasIssue,
              completedPercent: completed / qtyRented,
              itemList: [
                {
                  invoiceId: inv.id,
                  invoiceNumber: inv.invoiceNumber,
                  customer: inv.customer[CUSTOMER_DISPLAY_NAME],
                  ...item,
                },
              ],
            });
          }
        });
    });

  return sortByNum(dayItems, "completedPercent");
};
