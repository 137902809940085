import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { TrucksContext } from "./AdminTrucks";
import { Modal } from "antd";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { TRUCKS_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../app/system-components/toasters/notificationError";

const TruckContextMenu = ({ visible, item, x, y, refreshTable }) => {
  const { userData } = useSelector(authSelector);
  const modal = useContext(TrucksContext);
  const { writing, deleteDocument } = useFirestoreWrite();
  const handleDelete = () => {
    return Modal.confirm({
      title: "Delete truck?",
      content: "",
      okType: "danger",
      onOk() {
        deleteDocument({
          data: {
            collection: TRUCKS_COLLECTION,
            id: item?.id,
          },
        })
          .then(() => {
            refreshTable();
            notificationConfirm("Truck deleted");
          })
          .catch(() => {
            notificationError("Something went wrong");
          });
      },
      onCancel() {},
    });
  };
  console.log(item);
  return (
    <div>
      {visible && userData && item && (
        <ul
          className="tableContextMenu"
          style={{ left: `${x}px`, top: `${y}px`, padding: "4px 0" }}
        >
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={() => {
              modal.loadData(item);
              modal.show();
            }}
          >
            <TextBody text={"Edit"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleDelete}
          >
            <TextBody text={"Delete"} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default TruckContextMenu;
