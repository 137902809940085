import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import notificationError from "../../app/system-components/toasters/notificationError";
import VenueContextMenu from "./components/VenueContextMenu";
import VenueTable from "./components/VenueTable";
import { authSelector } from "../auth/authSlice";
import { COLOR_RED_0 } from "../../app/ui/colorConstants";
import { venueWrites } from "../../app/services/firestore/writes/venueWrites";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { adminVenueColumns } from "./adminVenueColumns";
import { useHistory } from "react-router-dom";
import { configureVenue } from "../../app/utils/models/configure/configureVenue";

const VenueContextMenuWrapper = ({
  loadingVenues,
  data,
  handleEdit,
  onDeleteComplete,
  onDuplicateComplete,
}) => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const [contextMenu, setContextMenu] = useState({
    item: null,
    visible: false,
    x: 0,
    y: 0,
  });
  const [saving, setSaving] = useState(false);
  const firestore = useFirestore();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleDeactivate = () => {
    Modal.confirm({
      title: "Make Inactive?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "Continuing will remove the venue from showing as an option on invoices as a delivery location",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          venueWrites({ firestore }, fsOrgPrefix)
            .editVenue(contextMenu.item && contextMenu.item.id, {
              isActive: false,
            })
            .then(() => {
              setSaving(false);
              onDeleteComplete();
              resolve();
              notificationConfirm("Venue made inactive", "");
            })
            .catch((err) => {
              setSaving(false);

              console.log(err);
              notificationError(
                "Something went wrong",
                "Venue could not be made inactive"
              );
            });
        });
      },
      onCancel() {},
    });
  };
  const handleReactivate = () => {
    Modal.confirm({
      title: "Make Active?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "This will restore the venue and make it available to use on invoices as a delivery location",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          venueWrites({ firestore }, fsOrgPrefix)
            .editVenue(contextMenu.item && contextMenu.item.id, {
              isActive: true,
            })
            .then(() => {
              setSaving(false);
              onDeleteComplete();
              resolve();
              notificationConfirm("Venue made active", "");
            })
            .catch((err) => {
              setSaving(false);

              console.log(err);
              notificationError(
                "Something went wrong",
                "Venue could not be made active"
              );
            });
        });
      },
      onCancel() {},
    });
  };
  const handleDuplicate = () => {
    // create duplicate
    if (contextMenu.item) {
      setLoading(true);
      const payload = configureVenue().duplicateVenue(
        contextMenu.item,
        userData.id,
        orgData
      );
      venueWrites({ firestore }, fsOrgPrefix)
        .createVenue(payload)
        .then(() => {
          setLoading(false);
          onDuplicateComplete(() => {
            notificationConfirm("Venue duplicated");
          });
        });
    }

    // open drawer
  };
  const configureMenuOpts = (context) => {
    setContextMenu({
      item: context.item,
      visible: context.visible,
      x: context.x,
      y: context.y,
    });
  };

  const handleVenueClick = (c) => {
    handleEdit(c);
  };

  return (
    <div>
      {userData && orgData && (
        <VenueTable
          data={data}
          loadingVenues={loadingVenues}
          setContextMenu={configureMenuOpts}
          contextMenu={contextMenu}
          columns={adminVenueColumns(handleVenueClick)}
        />
      )}
      <VenueContextMenu
        item={contextMenu.item}
        visible={contextMenu.visible}
        x={contextMenu.x}
        y={contextMenu.y}
        history={history}
        handleEdit={() => handleEdit(contextMenu.item)}
        handleDeactivate={handleDeactivate}
        handleReactivate={handleReactivate}
        handleDuplicate={handleDuplicate}
      />
    </div>
  );
};

export default VenueContextMenuWrapper;
