import React, { useEffect, useState } from "react";
import { Affix, Button, Input, Modal, PageHeader, Space } from "antd";
import AddVenueModal from "./components/AddVenueModal";
import VenueContextMenuWrapper from "./VenueContextMenuWrapper";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { venueQueries } from "../../app/services/firestore/queries/venueQueries";
import { useFirestore } from "react-redux-firebase";
import EditVenueModal from "./components/EditVenueModal";

const { Search } = Input;
const AdminVenues = ({ history }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [venueModal, setVenueModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [venues, setVenues] = useState([]);
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [edit, setEdit] = useState({
    visible: false,
    selected: null,
  });
  const [keywords, setKeywords] = useState(null);

  const firestore = useFirestore();

  useEffect(() => {
    loadVenues();
  }, [fsOrgPrefix]);

  const loadVenues = () => {
    setLoading(true);
    fsOrgPrefix &&
      venueQueries({ firestore }, fsOrgPrefix)
        .fetchVenues()
        .then((res) => {
          setVenues([...res]);
          setLoading(false);
        });
  };

  useEffect(() => {
    if (!keywords) return;
    const filtered = venues.filter((i) => {
      const name = `${i.venueName.toLowerCase()}`;
      return name.includes(keywords.toLowerCase());
    });
    setFilteredVenues([...filtered]);
  }, [keywords, venues]);

  const handleClose = () => {
    setVenueModal(false);
  };
  const handleEdit = (c) => {
    setEdit({
      visible: true,
      selected: c,
    });
  };
  const handleCloseEdit = () => {
    setEdit({
      visible: false,
      selected: null,
    });
  };

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          backIcon={false}
          title="Venues"
          extra={
            <Space size={12}>
              <Search
                allowClear={true}
                key="Searchbar"
                placeholder="Search"
                onSearch={(value) => setKeywords(value)}
                style={{
                  width: 280,
                  fontWeight: "bold",
                }}
              />
              <Button type="primary" onClick={() => setVenueModal(true)}>
                Add Venue
              </Button>
            </Space>
          }
        />
      </Affix>
      <div className="list-margin-top" style={{ marginTop: "68px" }}>
        <VenueContextMenuWrapper
          data={!keywords ? venues : filteredVenues}
          loading={loading}
          handleEdit={handleEdit}
          onDeleteComplete={() => {
            handleClose();
            loadVenues();
          }}
          onDuplicateComplete={loadVenues}
        />
      </div>
      <AddVenueModal
        visible={venueModal}
        onCancel={handleClose}
        onComplete={() => {
          handleClose();
          loadVenues();
        }}
      />
      <EditVenueModal
        visible={edit.visible}
        selected={edit.selected}
        onCancel={handleCloseEdit}
        onComplete={() => {
          handleCloseEdit();
          loadVenues();
        }}
      />
    </div>
  );
};

export default AdminVenues;
