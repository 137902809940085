export default function convertTimeToString(createdOn) {
  if (createdOn) {
    return new Date(createdOn.seconds * 1000).toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return "Unknown time";
  }
}
