import React, { useContext } from "react";
import { Card, Progress, Typography } from "antd";
import { Cell, Legend, Pie, PieChart } from "recharts";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
} from "../../../../app/ui/colorConstants";
import { PS_PIE_COLORS } from "../../../routes/map/helpers/generateColor";
import { DepreciationReportContext } from "../DepreciationReport";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { getColorForPercentage } from "../../../../app/ui/colorMap";

const { Title } = Typography;
const DepreciationPie = ({ currentValue, totalValue }) => {
  const { state, actions } = useContext(DepreciationReportContext);
  const currPercent = currentValue / totalValue;

  return (
    <Card
      size={"small"}
      title={"Current Value (As of today)"}
      style={{ marginTop: "12px" }}
      bodyStyle={{ textAlign: "center", marginTop: "12px", paddingBottom: "0" }}
    >
      <Progress
        width={"250px"}
        strokeColor={{
          "0%": getColorForPercentage(currPercent),
          "100%": getColorForPercentage(0.1),
        }}
        type="dashboard"
        percent={currPercent * 100}
        format={(percent) => {
          const currentStr = `${currentValue?.toLocaleString("us", {
            maximumFractionDigits: 2,
          })}`;
          const totalStr = `${totalValue?.toLocaleString("us", {
            maximumFractionDigits: 2,
          })}`;
          return (
            <div style={{ textAlign: "center" }}>
              <Title
                level={3}
                style={{
                  margin: "0",
                  color: getColorForPercentage(currPercent),
                }}
              >{`${percent.toLocaleString("us", {
                maximumFractionDigits: 2,
              })}%`}</Title>
              <TextSubtitle text={`$${currentStr} / $${totalStr}`} />
            </div>
          );
        }}
      />
    </Card>
  );
};

export default DepreciationPie;
