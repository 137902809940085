import React from "react";
import { Spinner } from "../../../app/ui/spinner";
import { Table } from "antd";

const VenueTable = ({
  data,
  columns,
  loadingVenues,
  setContextMenu,
  contextMenu,
}) => {
  const configureContextMenu = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!contextMenu.visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setContextMenu({
          item: null,
          visible: false,
          x: 0,
          y: 0,
        });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setContextMenu({
      item: record,
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };
  return (
    <Table
      className="ManageVenueTable"
      style={{ margin: "12px" }}
      size="small"
      columns={columns}
      dataSource={data}
      rowKey={"id"}
      loadingVenues={{
        spinning: loadingVenues,
        indicator: Spinner,
        style: { marginTop: "80px" },
      }}
      pagination={{ defaultPageSize: 50 }}
      locale={{ emptyText: "No venues" }}
      scroll={{ y: "calc(100vh - 176px)" }}
      onRow={(record) => {
        return {
          onContextMenu: (event) => {
            if (setContextMenu) {
              configureContextMenu(event, record);
            }
          },
        };
      }}
    />
  );
};

export default VenueTable;
