import { Card, Form, Select, Space } from "antd";
import { useFirestoreFetch } from "../../../app/services/hooks/fetch/useFirestoreFetch";
import { taxRateQueries } from "../../../app/services/firestore/queries/taxRateQueries";
import { TaxRate } from "../../../app/interfaces/taxRate";
import { useContext } from "react";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";

export const EmbeddedTaxRate = () => {
  const {
    data: taxRates,
    fetching,
  } = useFirestoreFetch(taxRateQueries().fetchTaxRates);
  const { actions, state } = useContext(AdminEmbeddedContext);
  
  const renderOption = (t: TaxRate) => {
    const fixedWidthRate = (t.rate * 100).toFixed(2);
    const deactivatedText = !t.isActive ? " - Deactivated" : "";

    return `${t.name} (${fixedWidthRate}%)${deactivatedText}`;
  };

  return (
    <Card size={"small"} title={"Tax Rate"} style={{ marginTop: "24px" }}>
      <Space align={"start"}>
        <Form.Item style={{ width: "400px" }}>
          {!fetching && (
            <Select
              disabled={fetching}
              placeholder={"Select Tax Rate"}
              onSelect={(value) => {
                actions.taxRate.setRate(value);
              }}
              defaultValue={state.taxRateId}
            >
              {taxRates.map((r) => (
                <Select.Option key={`${r.id}_${r.isActive}`} value={r.id}>
                  {r && renderOption(r)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Space>
    </Card>
  );
};
