import { replace } from "./replace";
import { SortArrByNumber } from "../../../app/utils/Nifty/nifty";
import { EmbeddedCheckoutQuestion, EmbeddedSettingsState } from "../adminEmbeddedContext";
/**
 * QUESTION ACTIONS
 * {
 *     orgId: "12345",
 *     checkoutQuestions: [],
 *     checkoutConfig: { snippetColor: null, snippetMarginTop: null },
 *     checkoutSettings: {
 *       type: "wishlist", // can also be 'order',
 *       allowOverbooked: false,
 *     },
 *     blackoutDates: [],
 *   }
 * ***
 * **
 * *
 */
export const createNewQuestion = (state: EmbeddedSettingsState) => {
  const questions = state.checkoutQuestions;
  return replace(state, "checkoutQuestions", () => {
    return [
      ...questions,
      {
        question: "",
        isRequired: false,
        index: questions.length,
      },
    ];
  });
};
export const removeQuestion = (
  state: EmbeddedSettingsState,
  indexToRemove: number
) => {
  const questions = state.checkoutQuestions;
  return replace(state, "checkoutQuestions", () => {
    const sorted: EmbeddedCheckoutQuestion[] = SortArrByNumber(
      questions.filter((_q, i) => {
        return i !== indexToRemove;
      }),
      "index",
      "ascending"
    );
    return sorted.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
  });
};
export const updateRequiredQuestion = (
  state: EmbeddedSettingsState,
  props: EmbeddedCheckoutQuestion
) => {
  return replace(state, "checkoutQuestions", () => {
    let questions = state.checkoutQuestions;
    questions[props.index] = {
      index: props.index,
      question: props.question,
      isRequired: props.isRequired,
    };
    return questions;
  });
};
export const moveQuestionIndexUp = (
  state: EmbeddedSettingsState,
  props: EmbeddedCheckoutQuestion
) => {
  const questions: EmbeddedCheckoutQuestion[] = state.checkoutQuestions;
  if (props.index === 0)
    return replace(state, "checkoutQuestions", () => {
      return questions;
    });
  return replace(state, "checkoutQuestions", () => {
    const moveItemIndex = props.index;
    const replaceWithItemIndex = props.index - 1;
    const updated = questions.map((q) => {
      if (moveItemIndex === q.index) {
        return {
          ...q,
          index: replaceWithItemIndex,
        };
      } else if (q.index === replaceWithItemIndex) {
        return {
          ...q,
          index: moveItemIndex,
        };
      }
      return q;
    });
    return SortArrByNumber(updated, "index", "ascending");
  });
};
export const moveQuestionIndexDown = (state: EmbeddedSettingsState, question: EmbeddedCheckoutQuestion) => {
  const questions = state.checkoutQuestions;
  if (question.index === questions.length - 1)
    return replace(state, "checkoutQuestions", () => {
      return questions;
    });
  return replace(state, "checkoutQuestions", () => {
    const moveItemIndex = question["index"];
    const replaceWithItemIndex = question["index"] + 1;
    const updated = questions.map((q) => {
      if (moveItemIndex === q.index) {
        return {
          ...q,
          index: replaceWithItemIndex,
        };
      } else if (q.index === replaceWithItemIndex) {
        return {
          ...q,
          index: moveItemIndex,
        };
      }
      return q;
    });
    return SortArrByNumber(updated, "index", "ascending");
  });
};
export const updateQuestionText = (state: EmbeddedSettingsState, props: EmbeddedCheckoutQuestion) => {
  let questions = state.checkoutQuestions;
  questions[props.index] = {
    index: props.index,
    question: props.question,
    isRequired: props.isRequired,
  };
  return replace(state, "checkoutQuestions", () => {
    return [...questions];
  });
};
