import React, { createContext, useContext } from "react";
import { Affix, Col, Divider, PageHeader, Row, Spin } from "antd";
import DateRangePicker from "../sales/components/DateRangePicker";
import TaxDateRangePicker from "./components/TaxDateRangePicker";
import { useTaxReport } from "./useTaxReport";
import TaxStatisticCard from "./components/TaxStatisticCard";
import TaxChart from "./components/TaxChart";
import TaxInvoices from "./components/TaxInvoices";

export const TaxReportContext = createContext();
const TaxReport = () => {
  const TaxContext = useTaxReport();
  return (
    <div>
      <TaxReportContext.Provider value={TaxContext}>
        <Spin spinning={TaxContext.state.loading}>
          <Affix>
            <PageHeader className="PageHeader" title="Tax Report" />
          </Affix>
          <div style={{ margin: "16px", paddingTop: "56px" }}>
            <Row justify={"space-between"} wrap={false} align={"top"}>
              <Col span={14}>
                <TaxDateRangePicker />
              </Col>
              <Col span={10}>
                <TaxStatisticCard />
              </Col>
            </Row>
            <Divider />
            <TaxChart />
            <br />
            <TaxInvoices />
          </div>
        </Spin>
      </TaxReportContext.Provider>
    </div>
  );
};

export default TaxReport;
