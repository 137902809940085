import {
  VENUE_NAME,
  VENUE_FULL_ADDRESS_STR,
} from "../../app/utils/models/modelConstants/modelConstants";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { Space } from "antd";
import { CategoryTag } from "../../app/system-components/tags/Tags";

export const adminVenueColumns = (handleVenueClick) => {
  return [
    {
      title: "Venue",
      dataIndex: [VENUE_NAME],
      render: (text, record) => {
        return (
          <Space>
            <TextBody
              style={{ cursor: "pointer" }}
              text={text}
              onClick={() => handleVenueClick(record)}
            />
            {record && !record.isActive && <CategoryTag text={"Disabled"} />}
          </Space>
        );
      },
    },
    {
      title: "Address",
      dataIndex: [VENUE_FULL_ADDRESS_STR],
    },
  ];
};
