import React, { useContext } from "react";
import { UsageReportContext } from "../UsageReport";
import { Card, Typography } from "antd";
import { capitalizeFirstLetter } from "../../../../app/utils/casing/capitalizeFirstLetter";

const { Text } = Typography;
const OverbookedTooltip = ({ payload }) => {
  const { state, actions } = useContext(UsageReportContext);
  const configureAmt = () => {
    if (!payload) return 0;
    const p = payload?.[0]?.payload ?? null;
    if (!p)
      return {
        date: "",
        items: [],
      };
    let list = [];
    for (const [k, v] of Object.entries(payload?.[0]?.payload)) {
      list.push({
        item: k,
        value: v,
      });
    }
    return {
      date: payload?.[0]?.payload?.date ?? "",
      items: list,
    };
  };
  const amt = configureAmt();
  return (
    <Card size={"small"}>
      <Text style={{ fontWeight: "bold" }}>{amt?.date ?? ""}</Text>
      <br />
      {amt?.items?.map((i) => {
        if (i?.item === "date") return null;
        if (i?.value === 0) return null;
        return (
          <div>
            {i?.item}:{" "}
            {i?.value.toLocaleString("us", { maximumFractionDigits: 2 })}
          </div>
        );
      })}
    </Card>
  );
};

export default OverbookedTooltip;
