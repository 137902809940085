import React, { useContext } from "react";
import { Card } from "antd";
import SelectReportItems from "../../sales/components/SelectReportItems";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ItemTooltip from "../../sales/components/ItemTooltip";
import { abbreviateNumber } from "../../../../app/services/hooks/helpers/reports/abbrNumber";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
} from "../../../../app/ui/colorConstants";
import { EmptyTableText } from "../../../invoices/components/invoiceTable/modules/EmptyTable";
import { TaxReportContext } from "../TaxReport";
import SelectTaxItems from "./SelectTaxItems";
import TaxColTooltip from "./TaxColTooltip";

const TaxChart = () => {
  const { state, actions } = useContext(TaxReportContext);
  return (
    <Card
      size={"small"}
      title={"Sales Tax Breakdown"}
      extra={<SelectTaxItems />}
      style={{ height: "740px" }}
    >
      {state.chartData?.length !== 0 ? (
        <ResponsiveContainer width={"100%"} height={624}>
          <BarChart
            data={state.chartData}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <Tooltip cursor={{ fill: "none" }} content={TaxColTooltip} />
            <XAxis
              interval={0}
              dataKey={"name"}
              tick={{
                angle: 25,
                textAnchor: "start",
                dominantBaseline: "ideographic",
              }}
              tickFormatter={(value, index) => {
                const limit = 28; // put your maximum character
                if (value.length < limit) return `${value}`;
                return `${value.substring(0, limit)}...`;
              }}
              height={100}
            />
            <YAxis
              tickFormatter={(v) => `$${abbreviateNumber(v)}`}
              style={{
                fontSize: ".8rem",
                fontWeight: "600",
              }}
            />
            <Bar
              style={{
                cursor: "pointer",
              }}
              dataKey={"total"}
              // stackId={"id"}
              fill={COLOR_GREEN_0}
              // radius={[4, 4, 0, 0]}
            >
              {state.chartData.map((entry, index) => {
                return (
                  <Cell
                    fill={COLOR_BLUE_0}
                    // cursor="pointer"
                    key={`cell-${index}`}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <EmptyTableText text={"No data"} />
      )}
    </Card>
  );
};

export default TaxChart;
