import React, { useContext } from "react";
import { Card, List, Space, Tag } from "antd";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { ROUTE_EDIT_INVOICE } from "../../../../app/routes";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { ROW_TYPE_SUBTOTAL } from "../../../../app/utils/models/modelConstants/modelConstants";
import {
  COLOR_BLUE_0,
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
} from "../../../../app/ui/colorConstants";
import { TaxReportContext } from "../TaxReport";

const TaxInvoices = () => {
  const { state, actions } = useContext(TaxReportContext);
  console.log(state.filteredInvoices);
  return (
    <Card size={"small"}>
      <List
        header={"Invoices"}
        itemLayout="horizontal"
        dataSource={state.filteredInvoices}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Link
                  to={`${ROUTE_EDIT_INVOICE}${item?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TextBody
                    text={`#${item?.invoice.invoiceNumber}: ${item?.invoice.customer?.customerDisplayName}`}
                  />
                </Link>
              }
              description={
                <Space direction={"vertical"} size={0}>
                  <Space>
                    Invoice Total:{" "}
                    <NumberFormat
                      value={item?.invoice.total?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Space>
                  <Space>
                    Tax Total:{" "}
                    <NumberFormat
                      value={item?.taxAmt?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Space>
                  <Space>
                    Taxable Amount:{" "}
                    <NumberFormat
                      value={item?.totalTaxableAmt?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Space>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default TaxInvoices;
