import {
  PictureFilled,
  QuestionCircleOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
  COLOR_YELLOW_0,
} from "../../../../app/ui/colorConstants";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { Tooltip } from "antd";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import { getColorForPercentage } from "../../../../app/ui/colorMap";

export const invDepreciationColumns = (categories) => {
  const catList =
    categories &&
    categories.map((c) => {
      return {
        text: c.name,
        value: c.id,
      };
    });
  return [
    {
      title: "Item",
      dataIndex: "name",
      render: (text, record) => {
        if (!record.isAvailable && !record.isActive)
          return (
            <div
              // onClick={() => handleTitleClick(record)}
              style={{
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // cursor: "pointer",
              }}
            >
              <TextBody style={{ opacity: "0.5" }} text={text} />
              <Tooltip title="This item is no longer in inventory">
                <WarningFilled
                  style={{ color: COLOR_YELLOW_0, marginLeft: "4px" }}
                />
              </Tooltip>
            </div>
          );
        if (!record.isActive)
          return (
            <Tooltip title="This item is no longer in inventory">
              <TextBody
                // onClick={() => handleTitleClick(record)}
                style={{
                  opacity: "0.5",
                  whiteSpace: "no-wrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // cursor: "pointer",
                }}
                text={text}
              />
            </Tooltip>
          );
        if (!record.isAvailable)
          return (
            <div
              // onClick={() => handleTitleClick(record)}
              style={{
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // cursor: "pointer",
              }}
            >
              <TextBody text={text} />
              <Tooltip title="This item is unavailable and will not show up in available inventory">
                <WarningFilled
                  style={{ color: COLOR_YELLOW_0, marginLeft: "4px" }}
                />
              </Tooltip>
            </div>
          );
        return (
          <TextBody
            // onClick={() => handleTitleClick(record)}
            style={{
              whiteSpace: "no-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // cursor: "pointer",
            }}
            text={text}
          />
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "stock",
      width: 100,
    },
    {
      title: "Categories",
      dataIndex: "categories",
      render: (text, record) =>
        record.categories.map((c) => <CategoryTag text={c.name} key={c.id} />),
      filters: catList ? catList : [],
      onFilter: (value, record) => {
        return record.categoriesQueryHook.includes(value);
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (text, record) => {
        return (
          <div
            className="InvNotesCell"
            style={{
              maxHeight: "68px",
              whiteSpace: "no-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TextBody text={text} />
          </div>
        );
      },
    },
    {
      title: "Depreciated Value",
      dataIndex: "currentDepreciation",
      width: 160,
      sorter: (a, b) =>
        a?.currentDepreciation?.depreciatedPercentage -
        b?.currentDepreciation?.depreciatedPercentage,
      render: (depreciation, record) => {
        if (!depreciation) console.log(depreciation);
        return (
          <div>
            {Math.sign(depreciation?.depreciatedValue) === 1 ? (
              <TextBody
                text={`$${depreciation?.depreciatedValue.toLocaleString("us", {
                  maximumFractionDigits: 2,
                })} (${Math.round(
                  depreciation?.depreciatedPercentage * 100
                )}%)`}
                style={{
                  color: getColorForPercentage(
                    depreciation?.depreciatedPercentage
                  ),
                }}
              />
            ) : (
              <TextBody
                text={`$0`}
                style={{
                  color: getColorForPercentage(0),
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
};
