import React, { useContext } from "react";
import { Card, Col, Row, Tabs } from "antd";
import InventoryDepreciation from "./InventoryDepreciation";
import InventoryDepreciationTable from "./InventoryDepreciationTable";
import DepreciationPie from "./DepreciationPie";
import TruckDepreciationTable from "./TruckDepreciationTable";
import { DepreciationReportContext } from "../DepreciationReport";
import DepreciationSearch from "./DepreciationSearch";
import CategorizedBreakout from "./CategorizedBreakout";

const { TabPane } = Tabs;
const DepreciationTabView = () => {
  const { state, actions } = useContext(DepreciationReportContext);
  return (
    <Tabs
      onChange={(v) => actions.onTabChange(v)}
      defaultActiveKey="inventory"
      tabBarExtraContent={<DepreciationSearch />}
    >
      <TabPane tab="Inventory" key="inventory">
        <Card size={"small"}>
          <Row justify={"space-between"} wrap={false} align={"top"}>
            <Col flex={"auto"}>
              <InventoryDepreciationTable />
            </Col>
            <Col flex={"340px"} style={{ marginLeft: "16px" }}>
              <DepreciationPie
                currentValue={state.inventoryValue?.current}
                totalValue={state.inventoryValue?.total}
              />
              <CategorizedBreakout />
            </Col>
          </Row>
        </Card>
      </TabPane>
      <TabPane tab="Trucks" key="trucks">
        <Card size={"small"}>
          <Row justify={"space-between"} wrap={false} align={"top"}>
            <Col flex={"auto"}>
              <TruckDepreciationTable />
            </Col>
            <Col flex={"340px"} style={{ marginLeft: "16px" }}>
              <DepreciationPie
                currentValue={state.trucksValue?.current}
                totalValue={state.trucksValue?.total}
              />
            </Col>
          </Row>
        </Card>
      </TabPane>
    </Tabs>
  );
};

export default DepreciationTabView;
